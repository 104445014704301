import React from "react";
import styled from "styled-components";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaIdCard,
  FaUser,
} from "react-icons/fa";

const RowExpansionDetails = ({
  tblSolicitorInvoiceDetails,
  campaigncasecode,
}) => {
  if (!tblSolicitorInvoiceDetails || tblSolicitorInvoiceDetails.length === 0) {
    return <p>No details available</p>;
  }

  const detail = tblSolicitorInvoiceDetails.find(
    (detail) => detail.casecode === campaigncasecode
  );

  if (!detail) {
    return <p>No matching case details found.</p>;
  }

  return (
    <DetailsContainer>
      <DetailCard>
        <h5>Case Number: {detail.casenumber}</h5>
        <DetailRow>
          <FaUser className="icon" />
          <span>
            {detail.title} {detail.firstname} {detail.middlename || ""}{" "}
            {detail.lastname}
          </span>
        </DetailRow>
        <DetailRow>
          <FaMapMarkerAlt className="icon" />
          <span>
            {detail.address1}, {detail.city}, {detail.region},{" "}
            {detail.postalcode}
          </span>
        </DetailRow>
        {detail.mobile && (
          <DetailRow>
            <FaPhone className="icon" /> <span>{detail.mobile}</span>
          </DetailRow>
        )}
        {detail.email && (
          <DetailRow>
            <FaEnvelope className="icon" /> <span>{detail.email}</span>
          </DetailRow>
        )}
        {detail.ninumber && (
          <DetailRow>
            <FaIdCard className="icon" />{" "}
            <span>NI Number: {detail.ninumber}</span>
          </DetailRow>
        )}
      </DetailCard>
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 1rem;
`;

const DetailCard = styled.div`
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background: #fff;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  .icon {
    margin-right: 10px;
    color: #007bff;
  }
`;

export default RowExpansionDetails;
