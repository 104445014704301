import React, { useState } from "react";
import { Button } from "primereact/button";
import { FaFileExcel } from "react-icons/fa";
import { handlePostRequest } from "../../services/PostTemplate";

function ExportAllRTAReports() {
  const [loading, setLoading] = useState(false);

  const formatDate = (input) => {
    const date = new Date(input);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const exportAllReports = async () => {
    setLoading(true);
    try {
      const data = {
        fromDate: formatDate("2022-01-01"),
        toDate: formatDate(new Date()),
      };
      const res = await handlePostRequest(data, "rta/rtaCaseReport");

      if (res?.data?.length) {
        const csvContent =
          "data:text/csv;charset=utf-8," +
          [
            Object.keys(res.data[0]).join(","), // Headers
            ...res.data.map((row) => Object.values(row).join(",")), // Rows
          ].join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          `rta_report_${new Date().toISOString().slice(0, 10)}.csv`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.warn("No data available for export.");
      }
    } catch (error) {
      console.error("Failed to export RTA reports:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button disabled={loading} onClick={exportAllReports}>
      <FaFileExcel />
      &nbsp;{loading ? "Exporting..." : "Export All"}
    </Button>
  );
}

export default ExportAllRTAReports;
