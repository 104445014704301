import React, { useState } from "react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CSVLink } from "react-csv";
import { Chip } from "primereact/chip";
import { FaFileExcel } from "react-icons/fa";
import { handlePostRequest } from "../../services/PostTemplate";
import ExportAllCasesHdr from "./ExportAllCasesHdr";

function HdrCaseReport() {
  const [cases, setCases] = useState([]);
  const [expandedRows, setExpandedRows] = useState();
  const [loading, setLoading] = useState(false);
  const [value, onChange] = useState([new Date(), new Date()]);

  const formatDate = (input) => {
    var datePart = input?.match(/\d+/g),
      year = datePart?.[0],
      month = datePart?.[1],
      day = datePart?.[2];
    return day + "-" + month + "-" + year;
  };

  const searchCases = async () => {
    setLoading(true);
    try {
      const data = {
        fromDate: formatDate(value?.[0]?.toISOString()?.slice(0, 10)),
        toDate: formatDate(value?.[1]?.toISOString()?.slice(0, 10)),
      };
      const res = await handlePostRequest(data, "hdr/hdrCaseReport");
      setCases(res.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const statusTemplate = (rowData) => {
    return (
      <center>
        <Chip label={rowData?.caseStatus} className="p-px-4 custom-chip" />
      </center>
    );
  };

  const headers = [
    { label: "Date Created", key: "createdate" },
    { label: "Date Updated", key: "updatedate" },
    { label: "Claim Code", key: "claimcode" },
    { label: "Name", key: "name" },
    { label: "Introducer Company", key: "introducerCompany" },
    { label: "Introducer User", key: "introducerUser" },
    { label: "Solicitor Company", key: "solicitorCompany" },
    { label: "Solicitor User", key: "solicitorUser" },
    { label: "Case Status", key: "caseStatus" },
    { label: "Case Status On", key: "caseStatusOn" },
    { label: "Invoice Date", key: "invoiceDate" },
    { label: "CFA", key: "cfa" },
    { label: "Proof of ID", key: "proofOfId" },
    { label: "Proof of Address", key: "proofOfAddress" },
    { label: "AML", key: "aml" },
    { label: "Tenancy Agreement", key: "tenancyAgreement" },
    { label: "Landlord", key: "landlord" },
    { label: "Post Code", key: "cpostcode" },
  ];

  const rowExpansionTemplate = (data) => {
    return (
      <div className="expanded-card" style={{ display: "flex", width: "100%" }}>
        <div className="box" style={{ width: "33%", padding: "1em" }}>
          <strong>Introducer Company: </strong>
          <p>{data?.introducerCompany || "N/A"}</p>
          <strong>AML: </strong>
          <p>{data?.aml || "N/A"}</p>
          <strong>CFA : </strong>
          <p>{data?.cfa === "Y" ? "Yes" : "No"}</p>
          <strong>Claim Code: </strong>
          <p>{data?.claimcode || "N/A"}</p>
          <strong>Postcode: </strong>
          <p>{data?.cpostcode || "N/A"}</p>
          <strong>Date Created: </strong>
          <p>{data?.createdate || "N/A"}</p>
        </div>
        <div className="box" style={{ width: "33%", padding: "1em" }}>
          <strong>Solicitor : </strong>
          <p>{data?.solicitorUser || "N/A"}</p>
          <strong>Submitted On : </strong>
          <p>{data?.ctitle || "N/A"}</p>
          <strong>Landlord: </strong>
          <p>{data?.landlord || "N/A"}</p>
          <strong>Proof of Address : </strong>
          <p>{data?.proofOfAddress === "Y" ? "Yes" : "No"}</p>
          <strong>Proof of ID : </strong>
          <p>{data?.proofOfId === "Y" ? "Yes" : "No"}</p>
          <strong>Solicitor Company: </strong>
          <p>{data?.solicitorCompany || "N/A"}</p>
          <strong>Invoice Date: </strong>
          <p>{data?.invoiceDate || "N/A"}</p>
        </div>
        <div className="box" style={{ width: "33%", padding: "1em" }}>
          <strong>Tenancy Agreement: </strong>
          <p>{data?.tenancyAgreement || "N/A"}</p>
          <strong>Updated Date: </strong>
          <p>{data?.updatedate || "N/A"}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <ExportAllCasesHdr />
      <div className="expanded-card p-d-flex p-jc-between">
        <div className="p-d-flex ">
          <DateTimeRangePicker
            autoFocus={true}
            format="y-MM-dd"
            onChange={onChange}
            value={value}
          />
          &nbsp;
          <Button
            disabled={loading}
            label={loading ? "Searching..." : "Search"}
            onClick={searchCases}
          />
        </div>
        <CSVLink
          data={cases}
          headers={headers}
          filename={`hdr_report_${new Date().toISOString().slice(0, 10)}.csv`}
        >
          <Button disabled={loading}>
            <FaFileExcel />
            &nbsp;Export
          </Button>
        </CSVLink>
      </div>
      <div className="card p-datatable-sm mt-4">
        <DataTable
          value={cases}
          scrollable
          paginator
          rows={10}
          className="p-datatable-customers"
          emptyMessage="No data found."
          expandedRows={expandedRows}
          dataKey="claimcode"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ maxWidth: "60px" }}></Column>
          <Column field="name" header="Client Name" sortable />
          <Column field="introducerUser" header="Introducer" sortable />
          <Column field="solicitorUser" header="Solicitor" sortable />
          <Column field="caseStatusOn" header="Accident Date" sortable />
          <Column field="caseStatus" header="Status" body={statusTemplate} />
        </DataTable>
      </div>
    </>
  );
}

export default HdrCaseReport;
