import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import SideBox, {
  SideBoxWrapper,
} from "../../components/FormLayout/SideBoxSection";
import {
  AiFillDelete,
  AiOutlineEye,
  AiOutlineRedo,
  AiFillEdit,
} from "react-icons/ai";
import { handleGetRequest } from "../../services/GetTemplate";
import { useSearchParams, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/TCGlogo.png";
import { handlePostRequest } from "../../services/PostTemplate";
import { SplitButton } from "primereact/splitbutton";
import { Dialog } from "primereact/dialog";
import EditIvoiceDetails from "./EditInvoiceDetails";
import AddLines from "./AddLines";
import styled from "styled-components";
import InvoiceNotes from "./InvoiceNotes";
import EmailDetailButton from "./EmailDetailButton";
import { Chip } from "primereact/chip";

export function formatAmount(amount) {
  if (amount < 0) {
    return `-£${Math.abs(amount).toFixed(2)}`;
  }
  return `£${Math.abs(amount).toFixed(2)}`;
}

function InvoiceDetails() {
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [lovInvoicing, setLovInvoicing] = useState([]);
  const [solicitorRtaDataValue, setsolicitorRtaDataValue] = useState("");
  const [subtotalValue, setsubtotalValue] = useState("");
  const [totalValue, settotalValue] = useState("");
  const [statusOpitions, setstatusOpitions] = useState("");
  const [compaignType, setcompaignType] = useState("");
  const [isVisbleEditDetails, setIsVisbleEditDetails] = useState(false);
  const [invoiceEditDetailsData, setInvoiceEditDetailsData] = useState(false);
  const [searchParams] = useSearchParams();
  const invoiceCode = searchParams.get("invoicecode");
  const navigate = useNavigate();

  const handleStausChange = async (statuscode, invoiceheadid) => {
    const data = {
      caseId: invoiceheadid.toString(),
      toStatus: statuscode,
      reason: null,
    };
    await handlePostRequest(data, "invoicing/performActionOnInvoicing");
    getInvoiceDetails();
  };

  const getInvoiceDetails = async () => {
    const res = await handleGetRequest(
      `invoicing/getInvoiceDetail/${invoiceCode}`
    );
    setInvoiceDetails(res?.data);
    setsubtotalValue(res?.data?.amount);
    const statusOptions = res?.data?.tblRtastatuses?.map((item) => {
      return {
        label: item.descr,
        command: (e) => {
          handleStausChange(item.statuscode, res?.data.invoiceheadid);
        },
      };
    });
    setstatusOpitions(statusOptions);
    setcompaignType(res?.data.compaigntype);

    if (res) getLovInvoicing(res?.data);
  };

  useEffect(() => {
    settotalValue(subtotalValue + invoiceDetails?.vatAmount);
  }, [invoiceDetails?.vatAmount, subtotalValue]);

  const amountTemplate = ({ adjust, charge }) => {
    return formatAmount(charge - adjust);
  };

  const descriptionTemplate = (rowData) => {
    return rowData.description;
  };

  const handleDelete = async (invoicedetailid, invType) => {
    await handlePostRequest(
      {
        invoiceDetailId: invoicedetailid,
        compaingcode: invoiceDetails?.compaingcode,
        invType,
      },
      `invoicing/removeInvoiceFromDetail`
    );
    getInvoiceDetails();
  };

  const handleView = (code, campaigncode) => {
    const campaignMap = {
      1: `/rtadetails/${code}`,
      2: `/hireDetails/${code}`,
      3: `/hdrDetails/${code}`,
      // Add more mappings as needed
    };

    const defaultPath = "/rtadetails"; // Set a default path if campaigncode doesn't match any key

    const path = campaignMap[campaigncode] || defaultPath;
    navigate(path);
  };

  const getLovInvoicing = async ({ solicitorCode, compaingcode }) => {
    if (solicitorCode === null || compaingcode === null) return;
    const res = await handleGetRequest(
      `lovCaseForInvoicing/${solicitorCode}/${compaingcode}`,
      false
    );
    setLovInvoicing(res?.data);
  };

  const handleSolicitor = async (e) => {
    setsolicitorRtaDataValue(e.value);
  };

  const handleCaseAdd = async () => {
    if (solicitorRtaDataValue) {
      await handlePostRequest(
        {
          caseId: solicitorRtaDataValue?.code,
          compaingcode: invoiceDetails?.compaingcode,
          invoiceId: invoiceDetails?.invoiceheadid,
        },
        `invoicing/addCaseInInvoice`
      );
      getInvoiceDetails();
    }
  };

  const sendInvoiceEmail = async () => {
    const data = {
      invoiceId: invoiceCode,
    };
    try {
      const res = await handlePostRequest(data, `invoicing/sendInvoiceEmail`);
      window.open(res?.data?.InvoiceUrl, "_blank");
    } catch (e) {
      console.log(e);
    }
    getInvoiceDetails();
  };

  const chargeTemplate = ({ charge }) => {
    return formatAmount(charge);
  };

  const adjustTemplate = ({ adjust }) => {
    return formatAmount(adjust);
  };

  const actionsTemplate = ({
    invoicedetailid,
    casecode,
    adjust,
    charge,
    invType,
    campaigncode,
  }) => {
    return (
      <div className="d-flex">
        <div
          title="Delete"
          className="pointer hide-on-print"
          onClick={() => {
            handleDelete(invoicedetailid, invType);
          }}
        >
          <AiFillDelete />
        </div>
        {invType === "A" && (
          <div
            title="View"
            className="pointer hide-on-print"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              handleView(casecode, campaigncode);
            }}
          >
            <AiOutlineEye />
          </div>
        )}
        <div
          title="Edit"
          className="pointer hide-on-print"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            setInvoiceEditDetailsData({
              invoicedetailid,
              adjust,
              charge,
              invType,
            });
            setIsVisbleEditDetails(true);
          }}
        >
          <AiFillEdit />
        </div>
      </div>
    );
  };

  const sourceTemplate = ({ campaigncode }) => {
    switch (campaigncode) {
      case "1":
        return "RTA";
      case "2":
        return "HIRE";
      case "3":
        return "HDR";
      case "4":
        return "TENANCY";
      case "5":
        return "PCP";
      case "6":
        return "DB";
      case "7":
        return "OL";
      case "8":
        return "PL";
      case "9":
        return "EL";
      default:
        return "RTA";
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  return (
    <>
      <div>
        {" "}
        <SideBoxWrapper className="w-100">
          <div className="p-mb-1 hide-on-print d-flex">
            {/* <Dropdown
              className="Width__80 "
              options={lovInvoicing}
              value={solicitorRtaDataValue}
              onChange={(e) => {
                handleSolicitor(e);
              }}
              placeholder="Select"
              optionLabel="name"
            />
            <button className="btn btn-primary" onClick={handleCaseAdd}>
              {" "}
              <FaPlus /> Add Case{" "}
            </button> */}
            <AddLines
              invoiceheadid={invoiceDetails.invoiceheadid}
              getInvoiceDetails={getInvoiceDetails}
            />
          </div>
          <SideBox title="Invoice">
            <div id="printable">
              <div className="d-flex justify-content-between mb-4">
                <div>
                  <img src={Logo} alt="The claims group" width={60} />
                </div>
                <div className="col-md-3 offset-md-3">
                  <div className="row">
                    <div className="col-md-6">Invoice#</div>
                    <div className="col-md-6">
                      <strong>{invoiceDetails?.invoiceNumber}</strong>
                    </div>
                    <div className="col-md-6">Date of Invoice</div>
                    <div className="col-md-6">
                      <strong>{invoiceDetails?.invoiceDate}</strong>
                    </div>
                    <div className="col-md-6">Due Date</div>
                    <div className="col-md-6">
                      <strong>{invoiceDetails?.invoiceDate}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="bold-line" />
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-semibold">Invoice To:</h6>
                  <ul className="pl-1">
                    {invoiceDetails?.invoiceToName && (
                      <li>{invoiceDetails.invoiceToName}</li>
                    )}
                    {invoiceDetails?.invoiceToAddress1 && (
                      <li>{invoiceDetails.invoiceToAddress1}</li>
                    )}
                    {invoiceDetails?.invoiceToAddress2 && (
                      <li>{invoiceDetails.invoiceToAddress2}</li>
                    )}
                    {invoiceDetails?.invoiceToCity && (
                      <li>{invoiceDetails.invoiceToCity}</li>
                    )}
                  </ul>
                </div>
                <div className="col-md-4">
                  <h6 className="text-semibold">Invoice From:</h6>
                  <ul className="pl-1">
                    {invoiceDetails?.invoiceFromName && (
                      <li>{invoiceDetails.invoiceFromName}</li>
                    )}
                    {invoiceDetails?.invoiceFromAddress1 && (
                      <li>{invoiceDetails.invoiceFromAddress1}</li>
                    )}
                    {invoiceDetails?.invoiceFromAddress2 && (
                      <li>{invoiceDetails.invoiceFromAddress2}</li>
                    )}
                    {invoiceDetails?.invoiceFromCity && (
                      <li>{invoiceDetails.invoiceFromCity}</li>
                    )}
                    {invoiceDetails?.invoiceToPostcode && (
                      <li>{invoiceDetails.invoiceToPostcode}</li>
                    )}
                  </ul>
                </div>
                <div className="col-md-4">
                  <h6 className="text-semibold">Invoice Details:</h6>
                  <ul className="pl-1">
                    {invoiceDetails?.invoiceType === "S" && (
                      <>
                        <li>
                          <div className="d-flex justify-content-between">
                            <span>Account Name</span>
                            <span>{invoiceDetails?.accountName}</span>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            {" "}
                            <span>Account Number </span>
                            <span>{invoiceDetails?.accountNumber}</span>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            {" "}
                            <span>Sort Code </span>
                            <span>{invoiceDetails?.sortcode}</span>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            {" "}
                            <span>Vat number </span>
                            <span>{invoiceDetails?.varegno}</span>
                          </div>
                        </li>
                      </>
                    )}
                    <li>
                      <div className="d-flex justify-content-between">
                        <span>Total Instructions</span>
                        <span>{invoiceDetails?.totalInstructions}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex justify-content-between">
                        {" "}
                        <span>Issued By</span>
                        <span>{invoiceDetails?.issuedBy}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex justify-content-between">
                        {" "}
                        <span>Payment Method</span>
                        <span>{invoiceDetails?.paymentMethod}</span>
                      </div>
                    </li>
                    <hr></hr>
                    <li>
                      <div className="d-flex justify-content-between">
                        <strong>Current Status</strong>
                        <span>
                          <Chip
                            label={invoiceDetails?.status}
                            className="p-px-4 custom-chip"
                          />
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <StyledInvoiceDataTable
                className="p-0"
                value={invoiceDetails?.tblSolicitorInvoiceDetail}
                showGridlines
                responsiveLayout="scroll"
              >
                <Column
                  body={sourceTemplate}
                  field="code"
                  sortable
                  header="Source"
                ></Column>
                <Column
                  body={descriptionTemplate}
                  field="code"
                  sortable
                  header="Description"
                ></Column>
                <Column
                  field="amount"
                  sortable
                  header="Charge"
                  body={chargeTemplate}
                ></Column>
                <Column
                  field="adjust"
                  body={adjustTemplate}
                  sortable
                  header="Adjust"
                ></Column>
                <Column body={amountTemplate} sortable header="Amount"></Column>
                <Column
                  className="hide-on-print"
                  bodyClassName="hide-on-print"
                  body={actionsTemplate}
                  sortable
                  header="Acts"
                ></Column>
              </StyledInvoiceDataTable>
              <div className="row mt-4">
                <div className="col-md-4 offset-md-8">
                  <h6 className="text-semibold">
                    Total: {formatAmount(totalValue)}
                  </h6>
                  <hr className="my-2" />
                  <div className="d-flex justify-content-between">
                    <span className="text-semibold">Subtotal:</span>
                    <span>{formatAmount(subtotalValue)}</span>
                  </div>
                  <hr className="my-2" />
                  <div className="d-flex justify-content-between">
                    <span className="text-semibold">VAT :</span>
                    <span>{formatAmount(invoiceDetails?.vatAmount)}</span>
                  </div>
                  <hr className="my-2"></hr>
                  <div className="d-flex justify-content-between">
                    <span className="text-semibold">Total :</span>
                    <span>{formatAmount(totalValue)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2 " id="hide">
              <EmailDetailButton data={invoiceDetails?.tblEmail} />
              <button className="btn  btn-warning hide-on-print">
                <AiOutlineRedo /> Revert
              </button>
              <button
                className="btn  btn-warning hide-on-print p-ml-2 p-mr-2"
                onClick={sendInvoiceEmail}
              >
                Email
              </button>
              &nbsp;
              <SplitButton
                label="Savedsd"
                icon="pi pi-plus"
                model={statusOpitions}
              ></SplitButton>
            </div>
          </SideBox>
        </SideBoxWrapper>
      </div>
      <Dialog
        header={"Edit Invoice Details"}
        visible={isVisbleEditDetails}
        style={{ width: "50vw" }}
        onHide={() => setIsVisbleEditDetails(false)}
        draggable={false}
      >
        <EditIvoiceDetails
          invoiceEditDetailsData={invoiceEditDetailsData}
          getInvoiceDetails={getInvoiceDetails}
          setIsVisbleEditDetails={setIsVisbleEditDetails}
        />
      </Dialog>
      <InvoiceNotes invoiceHeadId={invoiceCode} />
    </>
  );
}

const StyledInvoiceDataTable = styled(DataTable)`
  td:first-child {
    width: 15%;
  }
  td:nth-child(2) {
    width: 50%;
  }
  td:nth-child(3) {
    width: 15%;
  }
`;

export default InvoiceDetails;
