import React from "react";
import styled from "styled-components";
import { AiOutlineFileSearch } from "react-icons/ai";

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;

  svg {
    font-size: 48px;
    margin-bottom: 16px;
  }

  p {
    font-size: 18px;
    color: #555;
  }
`;

function LogEmpty() {
  return (
    <EmptyContainer>
      <AiOutlineFileSearch />
      <p>No data available</p>
    </EmptyContainer>
  );
}

export default LogEmpty;
