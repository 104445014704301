import React from "react";
import styled from "styled-components";
import {
  RiCalendarLine,
  RiDeviceLine,
  RiMailLine,
  RiMailSendLine,
  RiMessage2Line,
  RiTimeLine,
} from "react-icons/ri";
import ShowAddressMap from "./ShowAddressMap";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc;
`;

const AuditDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const DetailItem = styled.div`
  flex-basis: calc(50% - 10px);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #555555;

  svg {
    font-size: 24px;
    margin-right: 10px;
  }

  span {
    font-size: 16px;
  }
`;

const renderYesNoIcon = (value) => (value === "Y" ? "✅" : "❌");

const ESignAudit = ({ data }) => {
  const {
    emailSent,
    smsSent,
    clientBrowser,
    clientDevice,
    emailDate,
    esignOpenDate,
    esignSubmitDate,
    lastupdatedate,
    smsDate,
    createdate,
    esignOpenIp,
    esignSubmitIp,
    esignOpenLat,
    esignOpenLon,
  } = data;

  const removeTimestamp = (date) => {
    if (!date) return null;
    return date.substring(0, 10);
  };

  return (
    <>
      <Container>
        <AuditDetails>
          <DetailItem>
            <RiMailSendLine />
            <span>Email Sent: {renderYesNoIcon(emailSent)}</span>
          </DetailItem>
          <DetailItem>
            <RiMailLine />
            <span>Email Date: {removeTimestamp(emailDate)}</span>
          </DetailItem>

          <DetailItem>
            <RiMessage2Line />
            <span>SMS Sent: {renderYesNoIcon(smsSent)}</span>
          </DetailItem>
          <DetailItem>
            <RiCalendarLine />
            <span>SMS Date: {removeTimestamp(smsDate)}</span>
          </DetailItem>
          <DetailItem>
            <RiTimeLine />
            <span>eSign Open Date: {removeTimestamp(esignOpenDate)}</span>
          </DetailItem>
          <DetailItem>
            <RiTimeLine />
            <span>eSign Submit Date: {removeTimestamp(esignSubmitDate)}</span>
          </DetailItem>
          <DetailItem>
            <RiDeviceLine />
            <span>Client Browser: {clientBrowser}</span>
          </DetailItem>
          <DetailItem>
            <RiDeviceLine />
            <span>Client Device: {clientDevice}</span>
          </DetailItem>
          <DetailItem>
            <RiTimeLine />
            <span>Create Date: {removeTimestamp(createdate)}</span>
          </DetailItem>
          <DetailItem>
            <RiTimeLine />
            <span>Last Update Date: {removeTimestamp(lastupdatedate)}</span>
          </DetailItem>
          <DetailItem>
            <RiTimeLine />
            <span>eSign Open IP: {esignOpenIp}</span>
          </DetailItem>
          <DetailItem>
            <RiTimeLine />
            <span>eSign Submit IP: {esignSubmitIp}</span>
          </DetailItem>
        </AuditDetails>
      </Container>
      <ShowAddressMap latitude={esignOpenLat} longitude={esignOpenLon} />
    </>
  );
};

export default ESignAudit;
