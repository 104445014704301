import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { FaMapMarkedAlt } from "react-icons/fa";
import styled from "styled-components";
import MapComponent from "../LaAddress/MapComponent";

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #0056b3;
  }
`;

function ShowAddressMap({ longitude, latitude }) {
  const [show, setShow] = useState(false);

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <>
      <div className="p-col-12 p-mt-2">
        <StyledButton onClick={() => setShow(true)}>
          <FaMapMarkedAlt />
          Show Address on Map
        </StyledButton>
      </div>
      <Dialog
        header={"Address Location"}
        visible={show}
        style={{ width: "60%" }}
        onHide={() => setShow(false)}
      >
        <MapComponent latitude={latitude} longitude={longitude} />
      </Dialog>
    </>
  );
}

export default ShowAddressMap;
