import React, { useState, useEffect, useRef, Fragment } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handlePostRequest, UploadFiles } from "../../services/PostTemplate";
import { initialValues, validationSchema } from "./RtaConstants";
import { getrtaDetails } from "../../redux/actions/rtaAction";
import ClaimantDetails from "./rtaFormComponents/ClaimantDetails";
import LitigationFriend from "./rtaFormComponents/LitigationFriend";
import AccidentInfo from "./rtaFormComponents/accidentInfo/AccidentInfo";
import VnPInfo from "./rtaFormComponents/VnPInfo";
import ClaimantVehicle from "./rtaFormComponents/ClaimantVehicle";
import ThirdPartyVehicle from "./rtaFormComponents/ThirdPartyVehicle";
import Passengers from "./rtaFormComponents/passengers/Passengers";
import PassengersTable from "./rtaFormComponents/passengers/PassengersTable";
import AccSubmissionCriteria from "../../components/AccSubmissionCriteria";
import Dropzone from "../../components/UploadModal/Dropzone";
import { formatClawBackDate } from "./rtaFormComponents/ClawbackDate";
import { campaignCodes } from "../../components/CampaignGeneric/campaignConfig";
import OicFields from "./OicFields";
import Witness from "./WitnessRequestComponents/Witness";
import { verifyCaptchaFailureAction } from "../../redux/actions/verifyCaptuaAction";

function RTA({
  edit,
  onSave,
  isSubmitFormik,
  handleReturnEdit,
  onSetIsSubmitFormikFalse,
  showAccSubmissionCriteria,
  formikRef,
}) {
  const [isLitigationFriend, setIsLitigationFriend] = useState(false);
  const [passengersData, setPassengersData] = useState([]);
  const [singlePassenger, setSinglePassenger] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [claimantAdress, setClaimantAdress] = useState(false);
  const [passengerIndex, setPassengerIndex] = useState(false);
  const [isDriver, setIsDriver] = useState("");
  const [isAccSubmissionCriteria, setisAccSubmissionCriteria] = useState(
    showAccSubmissionCriteria || false
  );
  const [accSubmissionCriteria, setaccSubmissionCriteria] = useState({});
  const [claimantInfo, setClaimantInfo] = useState({});
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [witnessesData, setWitnessesData] = useState([]);

  let fileInput = useRef(null);
  const handleFiles = async (e) => {
    const file = await e.target.files;
    await setFiles([...files, ...file]);
    setIsLoading(false);
  };

  //convert bytes to kb and mb
  const bytesToSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  const totalSize = bytesToSize(
    files.reduce((acc, curr) => {
      return acc + curr.size;
    }, 0)
  );

  const { rta } = useSelector((state) => state.rtaSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSubmitFormik) {
      formik.handleSubmit();
      onSetIsSubmitFormikFalse();
    }
  }, [isSubmitFormik]);

  useEffect(() => {
    if (edit) {
      fillFormFields();
      dispatch(verifyCaptchaFailureAction());
    }
  }, [edit, rta]);

  const fillFormFields = () => {
    const data = { ...rta };

    // To convert Y/N to true/false for radio buttons and checkboxes
    Object.keys(data).forEach((key) => {
      if (data[key] === "Y") {
        data[key] = true;
      }
      if (data[key] === "N") {
        data[key] = false;
      }
      if (data[key] === null) {
        data[key] = "";
      }
    });

    data["injclasscodes"] = data["injclasscodes"]?.map((item) => {
      return item.code;
    });

    if (data["injclasscodes"]?.length === 0) {
      data["injclasscodes"] = null;
    }

    Object.keys(data).forEach((key) => {
      if (formik.initialValues.hasOwnProperty(key)) {
        formik.setFieldValue(key, data[key]);
      }
    });

    formik.setFieldValue(
      "clawbackDate",
      formatClawBackDate(data?.clawbackDate)
    );

    formik.setFieldValue("circumcode", data?.circumcode?.toString());
    setPassengersData(data?.rtapassengers);
  };

  const handleSubmit = async (rtacode) => {
    if (files.length) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("rtaClaimCode", rtacode);
      files.forEach((file) => {
        formData.append("multipartFiles", file);
      });
      await UploadFiles(formData, "rta/addRtaDocument");
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      data["referencenumber"] = data.referencenumber.toString();
      data["introducer"] = accSubmissionCriteria?.introducer?.companycode;
      data["advisor"] = accSubmissionCriteria?.advisor?.usercode;
      data["witnesses"] = witnessesData;
      let passenData = passengersData;
      data["witnesses"] = witnessesData;
      if (passenData.length) {
        passenData.forEach((passenger) => {
          Object.keys(passenger).forEach((key) => {
            if (passenger[key] === true) {
              passenger[key] = "Y";
            }
            if (passenger[key] === false) {
              passenger[key] = "N";
            }
            if (passenger[key] === "") {
              passenger[key] = null;
            }
          });
        });
      }
      data["passengers"] = passenData;
      data["injlength"] = data["injlength"]?.toString();

      Object.keys(data).forEach((key) => {
        if (data[key] === true) {
          data[key] = "Y";
        }
        if (data[key] === false) {
          data[key] = "N";
        }
        if (data[key] === "") {
          data[key] = null;
        }
      });
      if (edit) {
        data["rtacode"] = rta?.rtacode;
        const res = await handlePostRequest(data, "rta/updateRtaCase");
        handleReturnEdit();
        getDetails(res?.data?.rtacode);
      } else {
        try {
          console.log("data", data);
          const res = await handlePostRequest(data, "rta/addNewRtaCase");
          handleSubmit(res?.data?.rtacode);
          navigate(`/rtadetails/${res.data?.rtacode}`);
        } catch (error) {
          console.log(error);
        }
      }
      onSetIsSubmitFormikFalse();
    },
  });

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formikRef, formik]);

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const getDetails = async (rtacode) => {
    await dispatch(getrtaDetails(rtacode));
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  const handleLitigationFriend = (isShow) => {
    formik.setFieldValue("minor", isShow);
    setIsLitigationFriend(isShow);
  };

  const handlePassengers = (data, editMode) => {
    if (editMode) {
      let p = passengersData;
      p[passengerIndex] = data;
      setPassengersData(p);
    } else {
      setPassengersData([...passengersData, data]);
    }
    setIsEdit(false);
  };

  const onRemove = (index) => {
    setPassengersData((passengersData) =>
      passengersData.filter((_, i) => i !== index)
    );
  };

  const onEdit = (index) => {
    setPassengerIndex(index);
    setSinglePassenger(passengersData[index]);
    setIsEdit(true);
  };

  const setEditFalse = () => {
    setIsEdit(false);
  };

  useEffect(() => {
    setClaimantAdress({
      city: formik.values.city,
      region: formik.values.region,
      postalcode: formik.values.postalcode,
      address1: formik.values.address1,
      address2: formik.values.address2,
      address3: formik.values.address3,
      postcode: formik.values.postalcode,
    });
  }, [
    formik.values.city,
    formik.values.region,
    formik.values.postalcode,
    formik.values.address1,
    formik.values.address2,
    formik.values.address3,
  ]);

  useEffect(() => {
    setClaimantInfo({
      title: formik.values.title,
      firstname: formik.values.firstname,
      middlename: formik.values.middlename,
      lastname: formik.values.lastname,
      dob: formik.values.dob,
      address: claimantAdress,
    });
  }, [
    formik.values.title,
    formik.values.firstname,
    formik.values.lastname,
    formik.values.dob,
    formik.values.middlename,
    claimantAdress,
  ]);

  useEffect(() => {
    setIsDriver(formik.values.driverpassenger);
  }, [formik.values.driverpassenger]);

  const handleAccSum = (data) => {
    setisAccSubmissionCriteria(true);
    setaccSubmissionCriteria(data);
  };

  const handleDropZoneFiles = async (file) => {
    await setFiles([...files, ...file]);
  };

  return (
    <div>
      {!isAccSubmissionCriteria ? (
        <AccSubmissionCriteria
          handleAccSum={handleAccSum}
          campaignCode={campaignCodes.RTA}
        />
      ) : (
        <form onSubmit={edit ? onSave : formik.handleSubmit}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h6
                style={{
                  color: "#428BCA",
                  fontWeight: "600",
                  lineHeight: "1.42857143",
                }}
              >
                Claimant Info
              </h6>
              <small style={{ color: "#999" }}>Claimant information</small>
            </div>
            <div>
              {!edit && (
                <Button
                  type="submit"
                  icon="pi pi-arrow-circle-right"
                  iconPos="right"
                  className="btn btn-primary"
                  label="Next"
                  onClick={formik.handleSubmit}
                ></Button>
              )}
            </div>
          </div>
          <div>
            <hr className="border__hr" />
            <div className="row">
              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <ClaimantDetails
                    handleLitigationFriend={handleLitigationFriend}
                    {...props}
                    edit={edit}
                  />
                </div>
              </div>
              {isLitigationFriend && (
                <div className="col-md-6">
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <LitigationFriend edit={edit} {...props} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <AccidentInfo {...props} />
          </div>
          <div>
            <VnPInfo {...props} />
          </div>
          <div>
            <OicFields {...props} edit={edit} />
          </div>

          <Witness edit={true} setWitnessesData={setWitnessesData} />

          <div>
            <hr className="border__hr" />
            <div className="row">
              <div className="col-md-4">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <ClaimantVehicle {...props} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <ThirdPartyVehicle {...props} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div style={{ marginLeft: "30px" }}>
                    <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
                      Passengers
                    </h6>
                  </div>
                  <hr className="border__hr" />
                  {passengersData && passengersData.length > 0 && (
                    <div className="p-field p-col-12">
                      <PassengersTable
                        data={passengersData}
                        onRemove={onRemove}
                        onEdit={onEdit}
                        onUpdate={edit}
                      />
                    </div>
                  )}
                  <Passengers
                    handlePassengers={handlePassengers}
                    claimantAdress={claimantAdress}
                    singlePassenger={singlePassenger}
                    isEdit={isEdit}
                    onUpdate={edit} //for edit when rta is updating so hide the add button
                    setEditFalse={setEditFalse}
                    isDriver={isDriver}
                    claimantInfo={claimantInfo}
                  />
                </div>
              </div>
              <div className="col-md-6">
                {edit ? (
                  ""
                ) : (
                  <div className={!edit ? "pb-4" : ""}>
                    <div className="Panel__Heading">Attach Files</div>
                    <div className="files-table ">
                      <div className="row files-table-header">
                        <div className="col-md-8">Filename</div>
                        <div className="col-md-2">Size</div>
                        <div className="col-md-2">Status</div>
                      </div>
                      <div className="row files-table-body">
                        {files &&
                          files.map((file, index) => (
                            <Fragment key={index}>
                              <div className="col-md-8">{file.name}</div>
                              <div className="col-md-2">
                                {bytesToSize(file.size)}
                              </div>
                              <div className="col-md-2">
                                {isLoading ? "Uploading..." : "-"}
                              </div>
                            </Fragment>
                          ))}
                      </div>
                    </div>
                    <Dropzone handleDropZoneFiles={handleDropZoneFiles} />
                    <div className="mt-4">
                      <div className="row upload-footer">
                        <div className="col-md-8 px-4">
                          <button
                            type="button"
                            disabled={isLoading}
                            className="add-files"
                            onClick={() => fileInput.click()}
                          >
                            Add Files
                          </button>
                          <input
                            onChange={handleFiles}
                            type="file"
                            ref={(input) => (fileInput = input)}
                            multiple
                            hidden
                            accept="image/x-png,image/gif,image/jpeg,video/mp4,video/x-m4v,video/*"
                          />
                        </div>
                        <div className="col-md-2">
                          <small>{totalSize}</small>
                        </div>
                        <div className="col-md-2">
                          <small className="">
                            {" "}
                            {isLoading ? "Uploading..." : "-"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {isLitigationFriend && (
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <LitigationFriend {...props} />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default RTA;
