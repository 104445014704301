/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import React, { useState } from "react";
import { ImUpload2 } from "react-icons/im";
import Accordion from "../../components/Accordion";
import { FormWrapper } from "../../components/FormLayout/FormHeader";
import {
  LargeTab,
  LargeTabWrapper,
} from "../../components/FormLayout/FormHeader/LargeTab";
import UploadModal from "../../components/UploadModal";
import AffectedPersonModel from "./affectedperson";
import AffectedPersonTable from "./affectedperson/affectedpersontable";
import AffectedRoomModel from "./AffectedRoom";
import AffectedRoomTable from "./AffectedRoom/AffectedRoomTable";
import ClaimDetail from "./ClaimDetail";
import Messages from "../../components/Messages";
import Notes from "../../components/Notes";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHdrDetails } from "../../redux/actions/hdrAction";
import { handlePostRequest } from "../../services/PostTemplate";
import Multimedia from "../../components/Multimedia";
import Logs from "../../components/Logs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import NotesLegalInternal from "../../components/Notes/NotesLegalInternal";
import HdrClaimantAddress from "./HdrClaimantAddress";
import HdrJointTenantAddress from "./HdrJointTenantAddress";
import FormMainBody from "../../components/FormLayout/FormMainBody";
import { useEffect } from "react";
import EnergyDebtSection from "./EnergyDebtSection";
import CaseInvoicing from "../../components/CaseInvoicing/CaseInvoicing";
import UpdateInvoicingStatus from "../../components/CaseInvoicing/UpdateInvoicingStatus";
import { campaignCodes } from "../../components/CampaignGeneric/campaignConfig";

function ViewHdrForm({ hdrCode }) {
  const nameTitleOptions = [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    { label: "Miss", value: "Miss" },
    { label: "Ms", value: "Ms" },
    { label: "Mstr", value: "Mstr" },
    { label: "DR", value: "DR" },
    { label: "Prof", value: "Prof" },
    { label: "Rev", value: "Rev" },
  ];
  const messages = useSelector((state) => state.notesSlice?.notes?.tblHdrnotes);
  const internalMessages = useSelector(
    (state) => state.notesSlice?.notes?.tblhdrnotesLegalOnly
  );
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCat = user?.tblUsercategory?.categorycode;

  const [active, setActive] = useState(0);
  const [mainClaimant, setmainClaimant] = useState({});
  const [affectedPerson, setaffectedPerson] = useState([]);
  const [affectedRoom, setaffectedRoom] = useState([]);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayAffectedRoom, setDisplayAffectedRoom] = useState(false);
  const [jointTenantDetails, setJointTenantDetails] = useState({});
  const [isJointTenancy, setisJointTenancy] = useState("");
  const [showJointTenant, setshowJointTenant] = useState(false);
  const [showUploadModal, setshowUploadModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, seterrors] = useState({});
  const [claimantDetails, setclaimantDetails] = useState({});
  const [tenantDetails, settenantDetails] = useState({});
  const [leadgen, setleadgen] = useState();
  const [afftectedHealth, setAfftectedHealth] = useState({
    healthAffected: "N",
    healthRelDetails: "",
  });
  const [hdrClaimantAddressobj, sethdrClaimantAddressobj] = useState({});
  const [hdrJointTenantAddressobj, sethdrJointTenantAddressobj] = useState({});
  const [hdrEnergyDebtObj, sethdrEnergyDebtObj] = useState({});
  const dispatch = useDispatch();
  const { hdr } = useSelector((state) => state.hdrSlice);
  const documents = hdr?.tblHdrdocuments?.filter(
    (tblRtadocument) => tblRtadocument.tblTask === null
  );
  const signingSecitonDocuments = hdr?.tblHdrdocuments?.filter(
    (tblHdrdocument) => tblHdrdocument.tblTask !== null
  );
  const [clawbackDate, setClawbackDate] = useState(null);

  useEffect(() => {
    if (hdr?.tblHdrclaimants?.length) {
      setmainClaimant({
        ...hdr.tblHdrclaimants[0],
      });
    }
  }, [hdr?.tblHdrclaimants]);

  useEffect(() => {
    if (hdr?.clawbackDate !== null) {
      setClawbackDate(hdr?.clawbackDate?.split("-").reverse().join("-"));
    }
  }, [hdr?.clawbackDate]);

  useEffect(() => {
    if (hdr?.tblHdrjointtenancies?.length) {
      setJointTenantDetails({
        ...hdr?.tblHdrjointtenancies?.[0],
      });
      setshowJointTenant(true);
      setisJointTenancy("yes");
    } else {
      setisJointTenancy("no");
    }
  }, [hdr?.tblHdrjointtenancies]);

  useEffect(() => {
    setleadgen(hdr?.tblUser?.loginid);
    setaffectedPerson(hdr?.tblHdraffectedpers);
    setaffectedRoom(hdr?.tblHdraffectedrooms);
    if (hdr?.healthAffected === "Y") {
      setAfftectedHealth({
        healthAffected: "Y",
        healthRelDetails: hdr?.healthRelDetails,
      });
    }
  }, [hdr]);

  const handleAddAffected = (affected) => {
    let newArr = JSON.parse(JSON.stringify(affectedPerson));
    newArr.push(affected);
    newArr[newArr.length - 1].id = newArr.length;
    setaffectedPerson(newArr);
  };

  const handleAddAffectedRoom = (affected) => {
    let newArr = JSON.parse(JSON.stringify(affectedRoom));
    newArr.push(affected);
    newArr[newArr.length - 1].id = newArr.length;
    setaffectedRoom(newArr);
  };

  const handleRemoveAffected = (id) => {
    let newArr = JSON.parse(JSON.stringify(affectedPerson));
    setaffectedPerson(newArr.filter((elm) => Number(elm?.id) !== Number(id)));
  };

  const handleRemoveAffectedRoom = (id) => {
    let newArr = JSON.parse(JSON.stringify(affectedRoom));
    setaffectedRoom(newArr.filter((elm) => Number(elm?.id) !== Number(id)));
  };

  const handleUpdateAffected = (affected) => {
    const filtered = affectedPerson.filter((elm) => elm?.id !== affected?.id);
    const index = affectedPerson.findIndex((elm) => elm?.id === affected?.id);
    filtered.splice(index, 0, affected);
    setaffectedPerson(filtered);
  };

  const handleUpdateAffectedRoom = (affected) => {
    const filtered = affectedRoom.filter((elm) => elm?.id !== affected?.id);
    const index = affectedRoom.findIndex((elm) => elm?.id === affected?.id);
    filtered.splice(index, 0, affected);
    setaffectedRoom(filtered);
  };

  const handleJointTenancy = (e) => {
    setisJointTenancy(e.value);
    setshowJointTenant(e.value === "yes" ? true : false);
  };

  const uploadAction = (
    <div
      className="text-semibold pointer"
      onClick={() => setshowUploadModal(true)}
    >
      <ImUpload2 /> &nbsp; Upload
    </div>
  );

  const hdrDetails = async () => {
    setIsLoading(true);
    await dispatch(getHdrDetails(hdrCode));
    setIsLoading(false);
  };

  const onDeleteDocument = () => {
    hdrDetails();
  };

  const onSave = async () => {
    mainClaimant["isJointTenancy"] = isJointTenancy;
    // add hdrClaimantAddressobj to mainClaimant
    hdrEnergyDebtObj["outstandingDebt"] =
      hdrEnergyDebtObj?.outstandingDebt === true
        ? "Y"
        : hdrEnergyDebtObj?.outstandingDebt === "Y"
        ? "Y"
        : "N";
    const updatedMainClaimant = {
      ...mainClaimant,
      ...hdrClaimantAddressobj,
      ...hdrEnergyDebtObj,
    };
    // add hdrJointTenantAddressobj to jointTenantDetails
    const updatedJointTenantDetails = {
      ...jointTenantDetails,
      ...hdrJointTenantAddressobj,
    };
    const updatedClaimantDetails = {
      ...claimantDetails,
      healthAffected: afftectedHealth?.healthAffected,
      healthRelDetails: afftectedHealth?.healthRelDetails,
    };
    setclaimantDetails(updatedClaimantDetails);
    const claimantDetailsWithClawback = {
      ...claimantDetails,
      clawbackDate: clawbackDate,
    };
    const hdrJson = {
      updateTblHdrclaim: claimantDetailsWithClawback,
      updateTblHdrtenancy: tenantDetails,
      updateTblHdrclaimant: updatedMainClaimant,
      updateTblHdrJointTenancy: updatedJointTenantDetails,
      updateTblHdraffectedperList: affectedPerson,
      updateTblHdraffectedroomList: affectedRoom,
    };
    // const allJson = {
    //   ...mainClaimant,
    //   ...jointTenantDetails,
    //   ...affectedPerson,
    //   ...affectedRoom,
    // };

    // const isvalid = await hdrValidation(allJson);

    // seterrors(isvalid?.errors);
    // if (!Object.keys(isvalid?.errors).length) {
    //   await handlePostRequest(hdrJson, "/hdr/updateHdrCase");
    // }

    try {
      await handlePostRequest(hdrJson, "/hdr/updateHdrCase");
      setDisabled(true);
      await hdrDetails();
    } catch (err) {
      setDisabled(true);
    }
  };

  const onCancel = () => {
    setDisabled(true);
  };

  React.useEffect(() => {
    hdrDetails();
  }, [hdrCode]);

  const handlePrintForm = () => {
    const input = document.getElementById("printable");
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("file.pdf");
    });
  };

  return (
    <FormWrapper>
      <div id="printable">
        <LargeTabWrapper>
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(0)}
            active={active === 0}
            title="Main"
          />
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(1)}
            active={active === 1}
            title={`Images & Videos (${hdr?.tblHdrdocuments?.length || 0})`}
          />
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(2)}
            active={active === 2}
            title="Notes"
          />
          {userCat === "4" ? (
            <>
              <LargeTab
                Icon="Icon"
                onClick={() => setActive(3)}
                active={active === 3}
                title="Messages"
              />
              <LargeTab
                Icon="Icon"
                onClick={() => setActive(4)}
                active={active === 4}
                title="Logs"
              />
            </>
          ) : null}
          <LargeTab
            onClick={() => setActive(5)}
            active={active === 5}
            title="Invoicing"
          />
        </LargeTabWrapper>
        {active === 0 && (
          <FormMainBody
            isEdit={!disabled}
            setIsEdit={(edit) => setDisabled(!edit)}
            disabledForm={String(disabled)}
            title="Case Info"
            onSave={onSave}
            onCancel={onCancel}
            editFlag={hdr?.editFlag}
          >
            <div>
              <label htmlFor="isJointTenancysyes" className="p-ml-1">
                Name :
              </label>
              <div className="row">
                <div className="col-md-2">
                  <Dropdown
                    options={nameTitleOptions}
                    placeholder="Select a Name"
                    value={mainClaimant?.ctitle}
                    className="Width__100"
                    onChange={(e) => {
                      setmainClaimant({
                        ...mainClaimant,
                        ctitle: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <InputText
                    placeholder="First"
                    value={mainClaimant?.cfname}
                    onChange={(e) => {
                      setmainClaimant({
                        ...mainClaimant,
                        cfname: e.target.value,
                      });
                    }}
                    className="p-d-block Width__100"
                  />
                </div>
                <div className="col-md-2">
                  <InputText
                    placeholder="Middle"
                    value={mainClaimant?.cmname}
                    onChange={(e) => {
                      setmainClaimant({
                        ...mainClaimant,
                        cmname: e.target.value,
                      });
                    }}
                    className="p-d-block Width__100"
                  />
                </div>
                <div className="col-md-2">
                  <InputText
                    onChange={(e) => {
                      setmainClaimant({
                        ...mainClaimant,
                        csname: e.target.value,
                      });
                    }}
                    value={mainClaimant?.csname}
                    placeholder="Surname"
                    className="p-d-block Width__100"
                  />
                </div>
              </div>
            </div>
            {userCat === "4" && hdr?.clawbackDate !== null && (
              <div>
                <label htmlFor="clawbackdate" className="p-ml-1">
                  Clawback Date :
                </label>
                <InputText
                  type="date"
                  value={clawbackDate}
                  onChange={(e) => {
                    setClawbackDate(e.target.value);
                  }}
                  className="p-d-block Width__100"
                />
              </div>
            )}
            <div className="row mt-2">
              <div className="col-md-4">
                <label htmlFor="isJointTenancysyes" className="p-ml-1">
                  Reference :
                </label>
                <InputText className="p-d-block Width__100" />
              </div>
              <div className="col-md-2">
                <label htmlFor="isJointTenancysyes" className="p-ml-1">
                  Joint Tenancy :
                </label>
                <div className="d-flex justify-content-between">
                  <div className="field-radiobutton">
                    <RadioButton
                      name="isJointTenancy"
                      onChange={(e) => handleJointTenancy(e)}
                      checked={isJointTenancy === "yes"}
                      value="yes"
                    />
                    <label htmlFor="isJointTenancysyes" className="p-ml-1">
                      Yes
                    </label>
                  </div>
                  <div className="field-radiobutton">
                    <RadioButton
                      name="isJointTenancy"
                      onChange={(e) => handleJointTenancy(e)}
                      checked={isJointTenancy === "no"}
                      value="no"
                    />
                    <label htmlFor="isJointTenancysNo" className="p-ml-1">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <label htmlFor="isJointTenancysyes" className="p-ml-1">
                Introducer Data :
              </label>
              <div className="row">
                <div className="col-md-2">
                  <InputText
                    disabled
                    placeholder="case id"
                    className="p-d-block Width__100"
                  />
                </div>
                <div className="col-md-2">
                  <InputText
                    disabled
                    placeholder="leadgen"
                    className="p-d-block Width__100"
                    value={leadgen}
                  />
                </div>
                <div className="col-md-2">
                  <InputText
                    disabled
                    placeholder="closer"
                    className="p-d-block Width__100"
                  />
                </div>
                <div className="col-md-2">
                  <InputText
                    disabled
                    placeholder="password"
                    className="p-d-block Width__100"
                  />
                </div>
              </div>
            </div>
            <div className="sub-form px-4">
              <Accordion title="Main Info" open>
                <div className="p-mt-4 p-ml-4">
                  <div className="p-fluid p-formgrid p-grid">
                    <div className="p-col-6">
                      <div className="p-fluid p-formgrid p-grid p-pr-4">
                        <div className="p-col-12 ">
                          <label className="p-d-block">Date of birth</label>
                          <InputText
                            type="date"
                            onChange={(e) => {
                              setmainClaimant({
                                ...mainClaimant,
                                cdob: e.target.value,
                              });
                            }}
                            value={mainClaimant?.cdob}
                            className="p-d-block Width__50"
                          />
                        </div>
                        <div className="p-col-12 ">
                          <label className="p-d-block">Ni Number</label>
                          <InputText
                            placeholder=""
                            className="p-d-block Width__50"
                            onChange={(e) => {
                              setmainClaimant({
                                ...mainClaimant,
                                cninumber: e.target.value,
                              });
                            }}
                            value={mainClaimant?.cninumber}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-col-6 p-fluid p-formgrid p-grid ">
                      <div className="p-col-6">
                        <label className="p-d-block">Mobile</label>
                        <InputText
                          placeholder="Mobile"
                          className="p-d-block Width__100"
                          onChange={(e) => {
                            setmainClaimant({
                              ...mainClaimant,
                              cmobileno: e.target.value,
                            });
                          }}
                          value={mainClaimant?.cmobileno}
                        />
                      </div>
                      <div className="p-col-6">
                        <label className="p-d-block">Landline</label>
                        <InputText
                          placeholder="Landline"
                          className="p-d-block Width__100"
                          onChange={(e) => {
                            setmainClaimant({
                              ...mainClaimant,
                              clandline: e.target.value,
                            });
                          }}
                          value={mainClaimant?.clandline}
                        />
                      </div>
                      <div className="p-col-12 p-mt-2 ">
                        <label className="p-d-block">
                          Best time to contact
                        </label>
                        <InputText
                          className="p-d-block Width__100"
                          onChange={(e) => {
                            setmainClaimant({
                              ...mainClaimant,
                              ccontacttime: e.target.value,
                            });
                          }}
                          value={mainClaimant?.ccontacttime}
                        />
                      </div>
                      <div className="p-col-12 p-mt-2 ">
                        <label className="p-d-block">Email</label>
                        <InputText
                          className="p-d-block Width__100"
                          onChange={(e) => {
                            setmainClaimant({
                              ...mainClaimant,
                              cemail: e.target.value,
                            });
                          }}
                          value={mainClaimant?.cemail}
                        />
                      </div>
                      <HdrClaimantAddress
                        sethdrClaimantAddressobj={sethdrClaimantAddressobj}
                        hdrClaimantAddressobj={hdrClaimantAddressobj}
                        hdr={hdr}
                      />
                    </div>
                    <EnergyDebtSection
                      sethdrEnergyDebtObj={sethdrEnergyDebtObj}
                      hdrEnergyDebtObj={hdrEnergyDebtObj}
                      hdr={hdr}
                    />
                    {showJointTenant && (
                      <div className="p-col-12">
                        <Accordion
                          open
                          title="Joint Tenant"
                          className="p-fluid p-formgrid p-grid"
                        >
                          <div className="p-col-6">
                            <div className="p-fluid p-formgrid p-grid p-pr-4">
                              <div className="p-col-12">
                                <label className="p-d-block">Name</label>
                                <Dropdown
                                  options={nameTitleOptions}
                                  placeholder="Select a Name"
                                  onChange={(e) => {
                                    setJointTenantDetails({
                                      ...jointTenantDetails,
                                      tTitle: e.target.value,
                                    });
                                  }}
                                  value={jointTenantDetails?.tTitle}
                                />
                              </div>
                              <div className="p-col-12 p-mt-2">
                                <InputText
                                  placeholder="First"
                                  className="p-d-block Width__100"
                                  onChange={(e) => {
                                    setJointTenantDetails({
                                      ...jointTenantDetails,
                                      tFname: e.target.value,
                                    });
                                  }}
                                  value={jointTenantDetails?.tFname}
                                />
                              </div>
                              <div className="p-col-12 p-mt-2">
                                <InputText
                                  onChange={(e) => {
                                    setJointTenantDetails({
                                      ...jointTenantDetails,
                                      tMname: e.target.value,
                                    });
                                  }}
                                  value={jointTenantDetails?.tMname}
                                  placeholder="Middle"
                                  className="p-d-block Width__100"
                                />
                              </div>
                              <div className="p-col-12 p-mt-2">
                                <InputText
                                  onChange={(e) => {
                                    setJointTenantDetails({
                                      ...jointTenantDetails,
                                      tSname: e.target.value,
                                    });
                                  }}
                                  value={jointTenantDetails?.tSname}
                                  placeholder="Surname"
                                  className="p-d-block Width__100"
                                />
                              </div>
                              <div className="p-col-12 p-mt-6">
                                <label className="p-d-block">
                                  Date of birth
                                </label>
                                <InputText
                                  onChange={(e) => {
                                    setJointTenantDetails({
                                      ...jointTenantDetails,
                                      tDob: e.target.value,
                                    });
                                  }}
                                  value={jointTenantDetails?.tDob}
                                  type="date"
                                  className="p-d-block Width__50"
                                />
                              </div>
                              <div className="p-col-12 p-mt-4">
                                <label className="p-d-block">NI number</label>
                                <InputText
                                  onChange={(e) => {
                                    setJointTenantDetails({
                                      ...jointTenantDetails,
                                      tNinumber: e.target.value,
                                    });
                                  }}
                                  value={jointTenantDetails?.tNinumber}
                                  placeholder="Ni Number"
                                  className="p-d-block Width__50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="p-col-6 p-fluid p-formgrid p-grid ">
                            <div className="p-col-6">
                              <label className="p-d-block">Mobile</label>
                              <InputText
                                onChange={(e) => {
                                  setJointTenantDetails({
                                    ...jointTenantDetails,
                                    tMobileno: e.target.value,
                                  });
                                }}
                                value={jointTenantDetails?.tMobileno}
                                placeholder="Mobile"
                                className="p-d-block Width__100"
                              />
                            </div>
                            <div className="p-col-6">
                              <label className="p-d-block">Landline</label>
                              <InputText
                                onChange={(e) => {
                                  setJointTenantDetails({
                                    ...jointTenantDetails,
                                    tLandline: e.target.value,
                                  });
                                }}
                                value={jointTenantDetails?.tLandline}
                                placeholder="Landline"
                                className="p-d-block Width__100"
                              />
                            </div>
                            <div className="p-col-12 p-mt-2">
                              <label className="p-d-block">Email</label>
                              <InputText
                                onChange={(e) => {
                                  setJointTenantDetails({
                                    ...jointTenantDetails,
                                    tEmail: e.target.value,
                                  });
                                }}
                                value={jointTenantDetails?.tEmail}
                                className="p-d-block Width__100"
                              />
                            </div>
                            <HdrJointTenantAddress
                              sethdrJointTenantAddressobj={
                                sethdrJointTenantAddressobj
                              }
                              hdrJointTenantAddressobj={
                                hdrJointTenantAddressobj
                              }
                              hdr={hdr}
                            />
                          </div>
                        </Accordion>
                      </div>
                    )}
                  </div>
                </div>
              </Accordion>
              <Accordion title="Claim Info" open>
                <ClaimDetail
                  view={true}
                  handleClaimReturn={(claim) => {
                    setclaimantDetails(claim);
                  }}
                  handleTenantReturn={(tenant) => {
                    settenantDetails(tenant);
                  }}
                  hasTenant
                  hdrFomData={hdr}
                />
                <div className="p-ml-4">
                  <div className="p-fluid p-formgrid p-grid">
                    <div className="p-col-6">
                      <div className="p-fluid p-formgrid p-grid p-pr-4">
                        <div className="p-col-12 p-mt-4">
                          <label className="p-d-block">
                            Has anybodys health been affected?
                          </label>
                        </div>
                        <div className="p-col-12">
                          <div className="field-radiobutton">
                            <RadioButton
                              inputId="afftectedhealthyes"
                              name="afftectedHealth"
                              value="Y"
                              onChange={(e) => {
                                setAfftectedHealth({
                                  ...afftectedHealth,
                                  healthAffected: e.value,
                                });
                              }}
                              checked={afftectedHealth.healthAffected === "Y"}
                            />
                            <label
                              htmlFor="afftectedhealthyes"
                              className="p-ml-1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="field-radiobutton">
                            <RadioButton
                              inputId="afftectedhealthno"
                              name="afftectedHealth"
                              value="N"
                              onChange={(e) => {
                                setAfftectedHealth({
                                  ...afftectedHealth,
                                  healthAffected: e.value,
                                  healthRelDetails: "",
                                });
                              }}
                              checked={afftectedHealth.healthAffected === "N"}
                            />
                            <label
                              htmlFor="afftectedhealthno"
                              className="p-ml-1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        <div className="p-col-12 p-mt-2">
                          <InputTextarea
                            placeholder="Enter daily routine effects related info here"
                            rows={5}
                            onChange={(e) => {
                              setAfftectedHealth({
                                ...afftectedHealth,
                                healthRelDetails: e.target.value,
                              });
                            }}
                            value={afftectedHealth.healthRelDetails}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <AffectedPersonModel
                    disabledForm={String(disabled)}
                    show={displayBasic}
                    hide={setDisplayBasic}
                    handleAffectedReturn={handleAddAffected}
                  />
                  <AffectedPersonTable
                    disabledForm={String(disabled)}
                    handleUpdateAffected={handleUpdateAffected}
                    affectedPerson={affectedPerson}
                    handleRemoveAffected={handleRemoveAffected}
                  />
                  <div
                    className="p-mt-4"
                    onClick={() => setDisplayBasic(!displayBasic)}
                  >
                    <p style={{ cursor: "pointer", color: "#555" }}>
                      --- affected person: click to add ---
                    </p>
                  </div>
                  <AffectedRoomModel
                    disabledForm={String(disabled)}
                    show={displayAffectedRoom}
                    hide={setDisplayAffectedRoom}
                    handleAffectedReturn={handleAddAffectedRoom}
                  />

                  <AffectedRoomTable
                    disabledForm={String(disabled)}
                    handleUpdateAffected={handleUpdateAffectedRoom}
                    affectedRoom={affectedRoom}
                    handleRemoveAffected={handleRemoveAffectedRoom}
                  />

                  <div
                    className="p-mt-4"
                    onClick={() =>
                      setDisplayAffectedRoom((display) => !display)
                    }
                  >
                    <p style={{ cursor: "pointer", color: "#555" }}>
                      --- affected room: click to add ---
                    </p>
                  </div>
                </div>
              </Accordion>
              <Accordion title="Additional questions"></Accordion>
              {/* <Accordion title="Images" open>
                <Multimedia documents={hdr?.tblHdrdocuments} />
              </Accordion> */}
            </div>
            <center className="mt-4">
              <button
                onClick={handlePrintForm}
                id="print-btn"
                className="btn btn-block btn-primary "
              >
                Print Claimant Form
              </button>
            </center>
          </FormMainBody>
        )}
        {active === 1 && (
          <>
            <Accordion title="Images" headerRightArea={uploadAction} open>
              <Multimedia
                documents={documents}
                onDeleteDocument={onDeleteDocument}
                deleteApi="hdr/deleteHdrDocument"
                documentType="hdrdocumentscode"
              />
            </Accordion>
            <Accordion title="Document Signing Section" open>
              <Multimedia
                documents={signingSecitonDocuments}
                onDeleteDocument={onDeleteDocument}
                deleteApi="hdr/deleteHdrDocument"
                documentType="hdrdocumentscode"
              />
            </Accordion>
          </>
        )}

        <UploadModal
          show={showUploadModal}
          hide={(show) => setshowUploadModal(show)}
          addFilesService={"/hdr/uploadHdrDocs"}
          codeName="hdrClaimCode"
          codeValue={hdrCode}
          returnSubmit={hdrDetails}
        />
        {active === 2 && (
          <Accordion title="Notes" open>
            <NotesLegalInternal internalMessages={internalMessages} />
            <Notes
              getNotesService={"hdr/getHdrCaseNotes"}
              addNotesService={"hdr/addHdrNotes"}
              caseType={"hdr"}
              code={hdrCode}
              messages={messages}
            />
          </Accordion>
        )}
        {active === 3 && (
          <Accordion title="Messages" open>
            <Messages
              code={hdrCode}
              url={"hdr/getHdrCaseMessages/"}
              posturl={"hdr/resendHdrEmail"}
              compainName={"hdrmessagecode"}
            />
          </Accordion>
        )}
        {active === 4 && (
          <Accordion title="Logs" open>
            <Logs api="hdr/getHdrAuditLogs" caseCode={hdr.hdrclaimcode} />
          </Accordion>
        )}
        {active === 5 && (
          <Accordion title="Invoicing" open>
            <CaseInvoicing
              api="hdr/getInvoiceDetails"
              caseCode={hdr?.hdrclaimcode}
            >
              <UpdateInvoicingStatus
                campaignCode={campaignCodes.HDR}
                caseCode={hdr?.hdrclaimcode}
                formData={{
                  isSolicitorInvoice: hdr?.isSolicitorInvoice,
                  isIntroducerInvoice: hdr?.isIntroducerInvoice,
                }}
                onUpdated={hdrDetails}
              />
            </CaseInvoicing>
          </Accordion>
        )}
      </div>
    </FormWrapper>
  );
}

export default ViewHdrForm;
