import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { handleGetRequest } from "../../../services/GetTemplate";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

const Card = styled.div`
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-left: 5px solid #007bff;
`;

const Header = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  color: #333;
`;

const SubHeader = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.5rem;
  color: #555;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const Tag = styled.span`
  background: #007bff;
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.85rem;
`;

const Status = styled.span`
  color: ${(props) => (props.active ? "green" : "red")};
  font-weight: bold;
`;

const MessageContainer = styled.div`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
`;

const ScheduleInfo = styled.div`
  font-size: 0.9rem;
  color: #555;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  gap: 0.3rem;
`;

function ViewAllBroadcast() {
  const navigate = useNavigate();
  const [broadcastList, setBroadcastList] = useState([]);
  const [expandedUsers, setExpandedUsers] = useState({});

  useEffect(() => {
    const getBroadcastList = async () => {
      try {
        const response = await handleGetRequest("getAllBroadCastMessages");
        setBroadcastList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getBroadcastList();
  }, []);

  const toggleUsers = (id) => {
    setExpandedUsers((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Container>
      {broadcastList.map((item) => {
        let usersArray = item.toUsers.split(",").map((user) => user.trim());
        if (usersArray[usersArray.length - 1] === "") {
          usersArray.pop();
        }
        const showMoreUsers = usersArray.length > 5;

        return (
          <Card key={item.id}>
            <Header>{item.subject}</Header>
            <Status active={item.status === "Y"}>
              {item.status === "Y" ? "Active" : "Inactive"}
            </Status>
            <ScheduleInfo>
              <div>Schedule Date: {item.sechduleDate}</div>
              <div>Schedule Time: {item.sechduleTime}</div>
              <div>Created Date: {item.createdate}</div>
              <div>Now: {item.now === "Y" ? "Yes" : "No"}</div>
            </ScheduleInfo>
            <SubHeader>To</SubHeader>
            <TagsContainer>
              {(expandedUsers[item.id]
                ? usersArray
                : usersArray.slice(0, 5)
              ).map((user, index) => (
                <Tag key={index}>{user}</Tag>
              ))}
            </TagsContainer>
            {showMoreUsers && (
              <ToggleButton onClick={() => toggleUsers(item.id)}>
                {expandedUsers[item.id] ? "Show Less Users" : "Show More Users"}
              </ToggleButton>
            )}
            <MessageContainer>
              <div dangerouslySetInnerHTML={{ __html: item.message }} />
            </MessageContainer>
          </Card>
        );
      })}
    </Container>
  );
}

export default ViewAllBroadcast;
