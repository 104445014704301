import React, { useEffect, useCallback, useState } from "react";
import { getESigns } from "../../redux/actions/eSignAction";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PdfViewer from "./PdfViewer";
import Signoff from "./Signoff";
import AlreadySigned from "./AlreadySigned";
import DynamicFields from "./DynamicFields";
import EsignPassword from "./EsignPassword/EsignPassword";
import GetUserLocation from "./GetUserLocation";

const ESign = () => {
  const eFields = useSelector((state) => state?.eSignSlice?.eFields);
  // get user from local storage
  const token = useSelector((state) => state?.authenticationSlice?.token);
  // if token is not found clear local storage
  const [showModal, setShowModal] = useState(true);
  const [location, setLocation] = useState({
    lat: null,
    long: null,
    error: null,
  });

  if (token) {
    localStorage.clear();
    window.location.href = "/";
  }

  const dispatch = useDispatch();
  const url = window.location.href;
  const urlWithoutParams = url.split("?")[1];
  const urlWithParams = urlWithoutParams.split("=");
  const encryptedUrl = urlWithParams[1];
  const campaignName = urlWithParams[0];
  const [campaignCode, setCampaignCode] = useState();
  const [password, setPassword] = useState("");
  const alreadySigned = useSelector(
    (state) => state?.eSignSlice?.eFields?.isEsign
  );
  const isDynamicField = useSelector(
    (state) => state?.eSignSlice?.eFields?.dynamicFields
  );

  const getCampaignCode = useCallback((campaignName) => {
    // uppercase the campaign name
    campaignName = campaignName.toUpperCase();
    switch (campaignName) {
      case "RTA":
        return "1";
      case "HIRE":
        return "2";
      case "HDR":
        return "3";
      case "EL":
        return "9";
      case "OL":
        return "7";
      case "PL":
        return "8";
      case "MN":
        return "11";
      case "PCP":
        return "5";
      default:
        return "1";
    }
  }, []);

  useEffect(() => {
    if (campaignName) {
      setCampaignCode(getCampaignCode(campaignName));
    }
  }, [campaignName, getCampaignCode]);

  const onSubmitPassword = async (pass) => {
    setPassword(pass);
    dispatch(
      await getESigns(
        campaignCode,
        campaignName,
        pass.trim(),
        encryptedUrl,
        url
      )
    );
  };

  useEffect(() => {
    if (eFields?.isEsign) {
      setShowModal(false);
    }
  }, [eFields]);

  return (
    <>
      {location.lat && location.long ? (
        <MainContainer>
          <center>
            <h3 className="mt-2">Conditional Fee Agreement</h3>
          </center>
          {alreadySigned && alreadySigned === "Y" ? (
            <AlreadySigned />
          ) : alreadySigned && alreadySigned === "N" ? (
            <>
              <DynamicFields
                campaignCode={campaignCode}
                campaignName={campaignName}
                encryptedUrl={encryptedUrl}
                url={url}
                pass={password}
                location={location}
              />
              {(isDynamicField && isDynamicField.length === 0) ||
              isDynamicField === null ? (
                <Signoff
                  campaignCode={campaignCode}
                  campaignName={campaignName}
                  encryptedUrl={encryptedUrl}
                  url={url}
                  pass={password}
                  location={location}
                />
              ) : null}
            </>
          ) : null}
          {Array.isArray(eFields?.esignDocPath) ? (
            eFields.esignDocPath.map((doc, index) => (
              <PdfViewer key={index} doc={doc} />
            ))
          ) : (
            <PdfViewer doc={eFields?.esignDocPath} />
          )}
        </MainContainer>
      ) : (
        <GetUserLocation location={location} setLocation={setLocation} />
      )}

      <EsignPassword
        onSubmitPassword={onSubmitPassword}
        showModal={showModal}
      />
    </>
  );
};

// Styled Components
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 100%;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 480px) {
    padding: 0px;
  }
`;

export default ESign;
