import React from "react";
import styled from "styled-components";
import { documentZipApi } from "../CampaignGeneric/campaignConfig";
import { handleGetRequest } from "../../services/GetTemplate";
import SideBox from "../FormLayout/SideBoxSection";
import { FaDownload } from "react-icons/fa";

const DownloadButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

function DownloadDocumentZip({ campaignName, code }) {
  const api = documentZipApi[campaignName];

  if (!api || !code) {
    return null;
  }

  const handleDownload = async () => {
    const res = await handleGetRequest(api + code);
    window.open(res.data);
  };

  return (
    <SideBox title="Download Documents" className="mt-4">
      <Container>
        <DownloadButton onClick={handleDownload}>
          <FaDownload /> Download ZIP
        </DownloadButton>
      </Container>
    </SideBox>
  );
}

export default DownloadDocumentZip;
