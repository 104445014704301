/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AiOutlineBars, AiOutlineExport, AiOutlineRight } from "react-icons/ai";
import { Tab, TabWrapper } from "../../components/Tab";
import CaseHeader from "../../components/CaseHeader";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getList, getStatus } from "../../redux/actions/rtaAction";
import { InputText } from "primereact/inputtext";
import { Chip } from "primereact/chip";
import styled from "styled-components";
import RtaFilterCaseListButton from "./RtaFilterCaseList/RtaFilterCaseListButton";

function ViewRTAList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState("All");
  const [filtered, setFiltered] = useState([]);
  const { rtaList, rtaStatus } = useSelector((state) => state.rtaSlice);
  const [expandedRows, setExpandedRows] = useState();
  const [loading, setLoading] = useState(false);

  const getStatuses = async () => {
    await dispatch(getStatus());
  };

  const getRta = async (status) => {
    setLoading(true);
    await dispatch(
      getList(
        status !== "All"
          ? `rta/getRtaCasesByStatus/${status}`
          : `/rta/getAuthRtaCases`
      )
    );
    setLoading(false);
  };

  const totalStatus = () => {
    let total = 0;
    rtaStatus &&
      rtaStatus.forEach(({ statusCount }) => {
        total += statusCount;
      });
    return total;
  };

  const actionsTemplate = ({ rtacode }) => {
    const newTablink = `../#/rtadetails/${rtacode}`;
    const link = `../rtadetails/${rtacode}`;

    return (
      <ButtonsContainer>
        <Button
          onClick={() => {
            navigate(link);
          }}
        >
          <StyledIcon>
            <AiOutlineRight />
          </StyledIcon>
          Open
        </Button>
        <Button
          onClick={() => {
            window.open(newTablink, "_blank");
          }}
        >
          <StyledIcon>
            <AiOutlineExport />
          </StyledIcon>
          Open in a new Tab
        </Button>
      </ButtonsContainer>
    );
  };

  const nameTemplate = (data) => {
    let { firstname, middlename, lastname } = data;
    firstname = firstname ? firstname : "";
    middlename = middlename ? middlename : "";
    lastname = lastname ? lastname : "";
    return <div>{firstname + " " + middlename + " " + lastname}</div>;
  };

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    getRta(currentStatus);
  }, [currentStatus]);

  useEffect(() => {
    onGlobalFilterChange();
  }, [rtaList]);

  const onGlobalFilterChange = (e = false) => {
    const keys = [
      "created",
      "rtanumber",
      "firstname",
      "contactdue",
      "code",
      "client",
      "status",
    ];
    const value = e?.target?.value?.toLowerCase();
    if (value) {
      const filteredList = rtaList.filter((item) => {
        const fullName = [item.firstname, item.middlename, item.lastname]
          .filter((name) => name) // Filter out null or undefined names
          .join(" ")
          .toLowerCase();

        if (fullName.includes(value)) return true; // Check if full name matches search value

        let flag = false;
        keys.forEach((key) => {
          if (key.includes(".")) {
            const [parent, child] = key.split(".");
            if (item[parent][child].toLowerCase().includes(value)) {
              flag = true;
            }
          } else if (
            key &&
            item[key] &&
            item[key].toString().toLowerCase().includes(value)
          ) {
            flag = true;
          }
        });
        return flag;
      });
      setFiltered(filteredList);
    } else {
      setFiltered(rtaList);
    }
  };

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        </span>
      </div>
    );
  };

  const header2 = renderHeader2();

  const rowExpansionTemplate = (data) => {
    return (
      <div className="expanded-card">
        <div className="p-grid">
          <div className="p-col-6">
            <div className="box">
              <strong>Accident Time : </strong>
              <p>{data?.acctime + " " + data?.accdate.split("T")[0]}</p>
            </div>
            <div className="box">
              <strong>Accident Description : </strong>
              <p>{data?.description}</p>
            </div>
            <div className="box">
              <strong>Injury Description : </strong>
              <p>{data?.injdescription}</p>
            </div>
          </div>
          <div className="p-col-6">
            <div className="box">
              <strong>Introducer : </strong>
              <p>{data?.introducer}</p>
            </div>
            <div className="box">
              <strong>Last Updated : </strong>
              <p>{data?.lastupdated}</p>
            </div>
            <div className="box">
              <strong>Last Note : </strong>
              <p>{data?.lastnote}</p>
            </div>
            <div className="box">
              <strong>Solicitor: </strong>
              <p>{data?.solicitor}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    if (rowData?.status === "Rejected" || rowData?.status === "New") {
      return (
        <center>
          <StatusChip status={rowData?.status}>{rowData?.status}</StatusChip>
        </center>
      );
    } else {
      return (
        <center>
          <Chip label={rowData?.status} className="p-px-4 custom-chip" />
        </center>
      );
    }
  };

  return (
    <>
      <CaseHeader
        title={"RTA Case List"}
        subTitle={"All claims, status: All"}
        search
        onSearch={(val) => console.log(val)}
      >
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeader>
      <RtaFilterCaseListButton />
      <TabWrapper>
        <Tab
          title={"All"}
          count={String(totalStatus())}
          active={currentStatus === "All"}
          icon={<AiOutlineBars />}
          onClick={() => setCurrentStatus("All")}
        />
        {rtaStatus.map(({ statusName, statusCount, statusCode }) => (
          <Tab
            key={statusCode}
            title={statusName}
            count={String(statusCount)}
            onClick={() => setCurrentStatus(statusCode)}
            active={currentStatus === statusCode}
            icon={<AiOutlineBars />}
          />
        ))}
      </TabWrapper>

      <div className="card">
        <DataTable
          value={filtered || []}
          showGridlines
          responsiveLayout="scroll"
          header={header2}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          paginator
          rows={10}
          emptyMessage
          loading={loading}
        >
          <Column expander style={{ width: "2.5rem" }}></Column>
          <Column
            field="createdon"
            sortable
            header="Created On"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="rtanumber"
            sortable
            header="Code"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="firstname"
            body={nameTemplate}
            sortable
            header="Client Name"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="contactdue"
            sortable
            header="Contact Due"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="rtataskname"
            sortable
            header="Current Task"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="status"
            body={statusTemplate}
            header="Status"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            header="Acts"
            body={actionsTemplate}
            filter
            filterMatchMode="contains"
          ></Column>
        </DataTable>
      </div>
    </>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const StyledIcon = styled.span`
  margin-right: 5px;
`;

const StatusChip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px; // This is shorthand for padding-top, padding-right, padding-bottom, padding-left
  font-size: 12px;
  width: 100px;
  color: white;
  background-color: ${({ status }) => getStatusColor(status)};
`;

const getStatusColor = (status) => {
  switch (status) {
    case "New":
      return "#2b9f2b"; // Green for 'New'
    case "Rejected":
      return "#df4848"; // Original Red for 'Rejected'
    default:
      return "#777777"; // Default gray for other statuses
  }
};

export default ViewRTAList;
