import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import EditUserButton from "./EditUser/EditUserButton";

function CompanyUserDetails({ userList, getuserList }) {
  const dt = useRef(null);
  const [visiblePasswords, setVisiblePasswords] = useState({});

  const togglePasswordVisibility = (id) => {
    setVisiblePasswords((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const passwordTemplate = (rowData) => {
    const isVisible = visiblePasswords[rowData.usercode];
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px" }}>
          {isVisible ? rowData.password : "••••••••"}
        </span>
        <Button
          icon={isVisible ? "pi pi-eye-slash" : "pi pi-eye"}
          className="p-button-text p-button-sm"
          onClick={() => togglePasswordVisibility(rowData.usercode)}
        />
      </div>
    );
  };

  const actionsTemplate = (data) => {
    return (
      <div className="d-flex justify-content-flex-start">
        <EditUserButton data={data} getuserList={getuserList} />
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <Chip
        label={rowData?.status === "Y" ? "Active" : "Inactive"}
        className="custom-chip p-px-4"
      />
    );
  };

  const rolesTemplate = (rowData) => {
    return (
      <>
        {rowData?.lovResponse?.map((role) => {
          return (
            <Chip key={role.name} label={role.name} className="p-mr-1 p-mt-1" />
          );
        })}
      </>
    );
  };

  return (
    <div className="card mt-2">
      <DataTable
        ref={dt}
        value={userList || []}
        loading={userList === null}
        paginator
        rows={10}
        emptyMessage="No Users found in this company. Please add a new User."
        header={"Company Users"}
      >
        <Column
          field="loginid"
          sortable
          header="Login ID"
          filter
          filterMatchMode="contains"
        />
        <Column
          field="username"
          sortable
          header="Email"
          filter
          filterMatchMode="contains"
        />
        <Column field="password" header="Password" body={passwordTemplate} />
        <Column field="lovResponse" header="Roles" body={rolesTemplate} />
        <Column
          field="companystatus"
          sortable
          body={statusTemplate}
          header="Status"
          filter
          filterMatchMode="contains"
        />
        <Column header="Action" body={actionsTemplate} />
      </DataTable>
    </div>
  );
}

export default CompanyUserDetails;
