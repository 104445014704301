import React, { useState } from "react";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";

function ExportAllHireReports() {
  const [loading, setLoading] = useState(false);

  const formatDate = (input) => {
    const date = new Date(input);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const exportAllReports = async () => {
    setLoading(true);
    const fromDate = formatDate("2022-01-01");
    const toDate = formatDate(new Date());

    const url = `report/getHireReport/${fromDate}/${toDate}`;

    try {
      const res = await handleGetRequest(url);
      res.data = res.data.replace(/\\/g, "/").replace(/http:/, "http://");
      res.data = res.data.replace(/https:/, "https://");
      window.open(res?.data, "_blank");
    } catch (error) {
      console.error("Failed to fetch the report:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={loading}
      label={loading ? "Exporting..." : "Export All"}
      onClick={exportAllReports}
    />
  );
}

export default ExportAllHireReports;
