import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chip } from "primereact/chip";
import styled from "styled-components";
import { FaCalendarAlt, FaUserTie, FaBriefcase } from "react-icons/fa";
import { InputText } from "primereact/inputtext";
import RowExpansionDetails from "./RowExpansionDetails";
import { invoiceNumberTemplate } from "../../pages/Invoice/InvoiceList";

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  span {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .icon {
    font-size: 1.2em;
    margin-right: 5px;
  }

  .redText {
    color: red;
  }

  .greenText {
    color: green;
  }
`;

const CaseInvoicingList = ({ invoiceList, campaigncasecode }) => {
  const [filtered, setFiltered] = useState([]);
  const [expandedRows, setExpandedRows] = useState();

  useEffect(() => {
    setFiltered(invoiceList);
  }, [invoiceList]);

  const descriptionTemplate = ({ createdon, duedate }) => {
    return (
      <StyledDescription>
        <span>
          <FaCalendarAlt className="icon" />
          <strong>Issued On :</strong>&nbsp;{createdon}
        </span>
        <span>
          <FaCalendarAlt className="icon" />
          <strong>Due On :</strong>&nbsp; {duedate}
        </span>
      </StyledDescription>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e?.target?.value?.toLowerCase();
    if (!value) return setFiltered(invoiceList);

    setFiltered(
      invoiceList.filter((item) =>
        [
          "compaigntype",
          "companyname",
          "invoicecode",
          "username",
          "createdon",
          "statuscode",
        ].some((key) => item[key]?.toString()?.toLowerCase()?.includes(value))
      )
    );
  };

  const rowExpansionTemplate = (data) => (
    <RowExpansionDetails
      tblSolicitorInvoiceDetails={data?.tblSolicitorInvoiceDetails}
      campaigncasecode={campaigncasecode}
    />
  );

  return (
    <div className="card">
      <StyledInvoiceDataTable
        value={filtered || []}
        showGridlines
        responsiveLayout="scroll"
        header={
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        }
        paginator
        rows={15}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander style={{ width: "3em" }} />
        <Column
          field="invoiceType"
          sortable
          header="Company Type"
          filter
          filterMatchMode="contains"
          body={(rowData) =>
            rowData?.invoiceType === "I" ? (
              <IntroducerChip>
                <FaUserTie style={{ marginRight: "8px" }} /> Introducer
              </IntroducerChip>
            ) : (
              <SolicitorChip>
                <FaBriefcase style={{ marginRight: "8px" }} /> Solicitor
              </SolicitorChip>
            )
          }
        />
        <Column
          field="invoicecode"
          sortable
          filter
          body={invoiceNumberTemplate}
          filterMatchMode="contains"
          header="Invoice Number"
        />
        <Column
          field="companyname"
          sortable
          filter
          filterMatchMode="contains"
          header="Bill to"
        />
        <Column
          field="createdon"
          body={descriptionTemplate}
          filter
          filterMatchMode="contains"
          sortable
          header="Description"
        />
        <Column
          field="statuscode"
          body={(rowData) => (
            <Chip label={rowData?.statuscode} className="p-px-4 custom-chip" />
          )}
          filter
          filterMatchMode="contains"
          sortable
          header="Status"
        />
      </StyledInvoiceDataTable>
    </div>
  );
};

const StyledInvoiceDataTable = styled(DataTable)`
  td:first-child {
    width: 10%;
  }
  td:nth-child(2) {
    width: 10%;
  }
  td:nth-child(3) {
    width: 15%;
  }
  td:nth-child(4) {
    width: 40%;
  }
`;

const ChipBase = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  font-weight: bold;
`;

const IntroducerChip = styled(ChipBase)`
  background-color: lightblue;
`;

const SolicitorChip = styled(ChipBase)`
  background-color: lightgreen;
`;

export default CaseInvoicingList;
