import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import SideBox from "../FormLayout/SideBoxSection";
import ChangeIntroducerButton from "../ChangeIntroducer/ChangeIntroducerButton";
import ChangeSolicitorButton from "../ChangeSolicitor/ChangeSolicitorButton";
import { campaignTaskKeys } from "../CampaignGeneric/campaignConfig";

const Section = styled.div`
  margin-bottom: 1rem;
`;

const Heading = styled.h3`
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
`;

const InfoText = styled.div`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

const CaseAccountInfo = ({
  introducerName,
  advisorName,
  campaign,
  campaignName,
  getCampaignDetails,
  solicitorcompanycode,
}) => {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const { compaignCodeKeyPostApi } = campaignTaskKeys[campaignName];

  const user = userStore && JSON.parse(userStore);
  if (user?.tblUsercategory?.categorycode === "2") {
    return null;
  }

  return (
    <SideBox title="Case Accounts" className="mt-4">
      <Section>
        <Heading>Introducer</Heading>
        <InfoText>
          <strong>{introducerName}</strong>
          <br />
          <small>{advisorName}</small>
        </InfoText>
        <ChangeIntroducerButton
          campaignName={campaignName}
          getCampaignDetails={getCampaignDetails}
          campaignCaseCode={campaign?.[compaignCodeKeyPostApi]}
        />
      </Section>

      <hr />

      {campaign?.solicitorcompany && (
        <Section>
          <Heading>Solicitor</Heading>
          <InfoText>
            <strong>{campaign?.solicitorcompany || ""}</strong>
            <br />
            <small>{campaign?.solicitorusername || ""}</small>
          </InfoText>
          <ChangeSolicitorButton
            campaignName={campaignName}
            getCampaignDetails={getCampaignDetails}
            campaignCaseCode={campaign?.[compaignCodeKeyPostApi]}
            solicitorcompanycode={solicitorcompanycode}
          />
        </Section>
      )}
    </SideBox>
  );
};

export default CaseAccountInfo;
