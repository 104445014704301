import React, { useState } from "react";
import styled from "styled-components";
import ChangeIntroDialog from "./ChangeIntroDialog";
import { useSelector } from "react-redux";

function ChangeIntroducerButton({
  campaignName,
  getCampaignDetails,
  campaignCaseCode,
}) {
  const [showModal, setShowModal] = useState(false);
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);

  const user = userStore && JSON.parse(userStore);
  if (user?.tblUsercategory?.categorycode !== "4") {
    return null;
  }
  return (
    <>
      <StyledButton
        type="button"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Change Introducer
      </StyledButton>
      <ChangeIntroDialog
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
        campaignName={campaignName}
        getCampaignDetails={getCampaignDetails}
        campaignCaseCode={campaignCaseCode}
      />
    </>
  );
}

const StyledButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* Adds space between buttons */
`;

export default ChangeIntroducerButton;
