import React, { useState } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../../../services/PostTemplate";
import validationSchema, { initialValues } from "../UserConstants";
import UserForm from "../UserForm";
import { FaSpinner } from "react-icons/fa";

function AddCompanyUser({ getuserList, onCloseModal, id }) {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (formData) => {
      const data = { ...formData };
      Object.keys(data).forEach((key) => {
        if (data[key] === true) {
          data[key] = "Y";
        }
        if (data[key] === false) {
          data[key] = "N";
        }
        if (data[key] === "") {
          data[key] = null;
        }
      });
      data["companycode"] = id;

      try {
        setLoading(true);
        await handlePostRequest(data, "userManagement/saveCompanyUser");
        onCloseModal();
        await getuserList();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <UserForm {...props} />
        <div align="center" className="p-col-12 p-md-12 mt-4">
          {loading ? (
            <div className="spinner-container">
              <FaSpinner className="spinner-icon" />
            </div>
          ) : (
            <Button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={loading}
            >
              Add User
            </Button>
          )}
        </div>
      </form>
    </>
  );
}

export default AddCompanyUser;
