import React from "react";
import CaseHeaderHdr from "./CaseHeaderHdr";
import { MainWrapper } from "../../components/FormLayout/FormHeader/index";
import {
  SideBoxWrapper,
  SideBox,
} from "../../components/FormLayout/SideBoxSection/index";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { AiOutlineBars } from "react-icons/ai";
import ViewHdrForm from "./ViewHdrForm";
import { useSelector } from "react-redux";
import HdrTasks from "./HdrTasks";
import InvoiceCaseDetails from "../../components/InvoiceCaseDetails.js/InvoiceCaseDetails";
import ESignAuditButton from "../../components/ESignAudit/ESignAuditButton";
import { useParams } from "react-router-dom";
import HdrCopyLogs from "./HdrCopyLogs";
import ChangeIntroducerButton from "../../components/ChangeIntroducer/ChangeIntroducerButton";
import { campaignsNames } from "../../components/CampaignGeneric/campaignConfig";
import { getHdrDetails } from "../../redux/actions/hdrAction";
import HDRSideBoxTimeline from "./HDRSideBoxTimeline";
import DownloadDocumentZip from "../../components/DownloadDocumentZip/DownloadDocumentZip";
import ChangeSolicitorButton from "../../components/ChangeSolicitor/ChangeSolicitorButton";

export default function HdrDetails() {
  const { hdr } = useSelector((state) => state.hdrSlice);
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const advisorname = hdr?.advisorname || "";
  const introducername = hdr?.introducername || "";
  const { hdrCode } = useParams();

  const solicitorcompanycode = hdr?.tblHdrsolicitors?.[0]?.companycode;
  const solCompanyCodeStatus =
    hdr?.tblHdrsolicitors?.[0]?.status === "Y" ? true : false;

  return (
    <>
      <CaseHeaderHdr
        title={hdr.claimcode}
        subTitle={`${hdr?.statusDescr}`}
        search
        onSearch={(val) => console.log(val)}
      >
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeaderHdr>
      <MainWrapper>
        <ViewHdrForm hdrCode={hdrCode} />
        <SideBoxWrapper>
          {user?.tblUsercategory?.categorycode === "4" ||
          user?.tblUsercategory?.categorycode === "1"
            ? hdr?.hdrCaseTasks?.length > 0 && <HdrTasks />
            : null}
          <DownloadDocumentZip
            campaignName={campaignsNames.hdr}
            code={hdr?.hdrclaimcode}
          />
          {(user?.tblUsercategory?.categorycode === "4" ||
            user?.tblUsercategory?.categorycode === "1") && (
            <SideBox title="Case Accounts" className="mt-4">
              <strong>{introducername}</strong>
              <br></br>
              <small>{advisorname}</small>
              <br></br>
              <ChangeIntroducerButton
                campaignName={campaignsNames.hdr}
                getCampaignDetails={getHdrDetails}
                campaignCaseCode={hdrCode}
              />
              {hdr &&
                "tblHdrsolicitors" in hdr &&
                hdr.tblHdrsolicitors &&
                hdr.tblHdrsolicitors.map(({ tblCompanyprofile }, index) => (
                  <div key={index}>
                    <br></br>
                    <hr />
                    <b>{tblCompanyprofile?.name}</b>
                    <br />
                    <small className="text-muted">
                      {tblCompanyprofile?.contactperson}
                    </small>
                    {index > 0 && <hr className="text-muted" />}
                  </div>
                ))}
              <ChangeSolicitorButton
                campaignName={campaignsNames.hdr}
                getCampaignDetails={getHdrDetails}
                campaignCaseCode={hdrCode}
                solicitorcompanycode={
                  solCompanyCodeStatus ? solicitorcompanycode : null
                }
              />
            </SideBox>
          )}
          <HdrCopyLogs hdrCopyLogs={hdr?.hdrCopyLogs} />
          <HDRSideBoxTimeline hdrclaimcode={hdrCode} />
          <InvoiceCaseDetails
            introducerInvoiceDate={hdr?.introducerInvoiceDate}
            solicitorInvoiceDate={hdr?.solicitorInvoiceDate}
            introducerInvoiceHeadId={hdr?.introducerInvoiceHeadId}
            solicitorInvoiceHeadId={hdr?.solicitorInvoiceHeadId}
            introducerInvoiceStatus={hdr?.introducerInvoiceStatus}
            solicitorInvoiceStatus={hdr?.solicitorInvoiceStatus}
          />
          <ESignAuditButton data={hdr?.tblEsignStatus} />
        </SideBoxWrapper>
      </MainWrapper>
    </>
  );
}
