import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import LaInput from "../../../components/LaInput";
import { Button } from "primereact/button";
import { getFilerHireList } from "../../../redux/actions/hireAction";
import LaDropdown from "../../../components/LaDropdown";
import { campaignCodes } from "../../../components/CampaignGeneric/campaignConfig";

function HireFilterCaseListForm({ onCloseModal }) {
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    postCode: "",
    portalCode: "",
    mobileNo: "",
    email: "",
    regNo: "",
    niNumber: "",
    introducerId: "",
    solicitorId: "",
  };
  const formik = useFormik({
    // validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      try {
        dispatch(getFilerHireList(data, "/hire/filterHireCases"));
        onCloseModal();
      } catch (error) {
        console.log(error);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };
  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="p-fluid p-formgrid p-grid p-pr-4">
        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Introducer</label>

          <LaDropdown
            keyName="introducerId"
            optionLabel="name"
            optionValue="companycode"
            placeholder="Select introducer"
            url={"getIntoducers/" + campaignCodes.HIRE}
            {...props}
          />
        </div>

        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Solicitor</label>

          <LaDropdown
            keyName="solicitorId"
            optionLabel="name"
            placeholder="Select Solicitor"
            url={"lovSolicitors/" + campaignCodes.HIRE}
            {...props}
          />
        </div>
        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Name</label>
          <LaInput keyName="name" placeholder="Name" {...props} />
        </div>

        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Post Code</label>
          <LaInput keyName="postCode" placeholder="Post Code" {...props} />
        </div>

        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Portal Code</label>
          <LaInput keyName="portalCode" placeholder="Portal Code" {...props} />
        </div>

        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Mobile Number</label>
          <LaInput keyName="mobileNo" placeholder="Mobile Number" {...props} />
        </div>

        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Email</label>
          <LaInput keyName="email" placeholder="Email" {...props} />
        </div>

        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">RegNo</label>
          <LaInput keyName="regNo" placeholder="regNo" {...props} />
        </div>

        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">NI Number</label>
          <LaInput keyName="niNumber" placeholder="NI Number" {...props} />
        </div>
      </div>
      <div className="p-d-flex p-jc-end mt-4">
        <Button
          type="submit"
          className="btn btn-primary"
          label="Search"
        ></Button>
      </div>
    </form>
  );
}

export default HireFilterCaseListForm;
