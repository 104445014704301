import React from "react";

function NotesInfo({ usercategorycode, fromUserCategory }) {
  // Function to map category codes to their corresponding labels
  const getCategoryLabel = (code) => {
    switch (code) {
      case "4":
        return "Internal";
      case "1":
        return "Introducer";
      case "7":
        return "Third Party";
      default:
        return "Solicitor";
    }
  };

  // Get labels for usercategorycode and fromUserCategory
  const toLabel = getCategoryLabel(usercategorycode);
  const fromLabel = getCategoryLabel(fromUserCategory);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <p>From: {fromLabel}</p>
      <p>To: {toLabel}</p>
    </div>
  );
}

export default NotesInfo;
