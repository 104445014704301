import React from "react";
import { RadioButton } from "primereact/radiobutton";

function VehicleType({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  return (
    <div>
      <hr className="border__hr" />
      <div className="row">
        <div className="col-md-4">
          <div style={{ marginLeft: "30px" }}>
            <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
              Vehicle Type
            </h6>
          </div>
          <hr className="border__hr" />
          <div className="p-fluid p-formgrid p-grid p-pr-4">
            <div className="p-col-12 p-mt-2">
              <label className="p-d-block">
                Select Vehicle Type: <span className="text-danger">*</span>
              </label>
              <div className="Radio__Btn">
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="vehicleType"
                    name="vehicleType"
                    value="C"
                    onChange={onChange}
                    checked={values.vehicleType === "C"}
                  />
                  <label htmlFor="Car" style={{ marginRight: "10px" }}>
                    Car
                  </label>
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="vehicleType"
                    name="vehicleType"
                    value="B"
                    onChange={onChange}
                    checked={values.vehicleType === "B"}
                  />
                  <label htmlFor="Bike" style={{ marginRight: "10px" }}>
                    Bike
                  </label>
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="vehicleType"
                    name="vehicleType"
                    value="P"
                    onChange={onChange}
                    checked={values.vehicleType === "P"}
                  />
                  <label htmlFor="Pedestrian" style={{ marginRight: "10px" }}>
                    Pedestrian
                  </label>
                </div>
              </div>
            </div>
          </div>
          {getFormErrorMessage("vehicleType")}
        </div>
      </div>
    </div>
  );
}

export default VehicleType;
