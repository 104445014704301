/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AiOutlineBars, AiOutlineExport, AiOutlineRight } from "react-icons/ai";
import { Tab, TabWrapper } from "../../components/Tab";
import CaseHeader from "../../components/CaseHeader";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getList, getStatus } from "../../redux/actions/hireAction";
import { InputText } from "primereact/inputtext";
import { Chip } from "primereact/chip";
import HireFilterCaseListButton from "./HireFilterCaseList/HireFilterCaseListButton";
import styled from "styled-components";

function ViewHireList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState("All");
  const [filtered, setFiltered] = useState([]);
  const [expandedRows, setExpandedRows] = useState();
  const { hireList, hireStatus } = useSelector((state) => state.hireSlice);
  const getStatuses = async () => {
    await dispatch(getStatus());
  };

  const getHire = async (status) => {
    await dispatch(
      getList(
        status !== "All"
          ? `hire/getHireCasesByStatus/${status}`
          : `hire/getHireCases`
      )
    );
  };

  const totalStatus = () => {
    let total = 0;
    hireStatus &&
      hireStatus.length &&
      hireStatus.forEach(({ statusCount }) => {
        total += statusCount;
      });
    return total;
  };

  const actionsTemplate = ({ hirecode }) => {
    const newTablink = `../#/hireDetails/${hirecode}`;
    const link = `../hireDetails/${hirecode}`;

    return (
      <ButtonsContainer>
        <Button
          onClick={() => {
            navigate(link);
          }}
        >
          <StyledIcon>
            <AiOutlineRight />
          </StyledIcon>
          Open
        </Button>
        <Button
          onClick={() => {
            window.open(newTablink, "_blank");
          }}
        >
          <StyledIcon>
            <AiOutlineExport />
          </StyledIcon>
          Open in a new Tab
        </Button>
      </ButtonsContainer>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="expanded-card">
        <div className="p-grid">
          <div className="p-col-4">
            <div className="box">
              <strong>Address : </strong>
              <p>{data?.address}</p>
            </div>
            <div className="box">
              <strong>Tel : </strong>
              <p>{data?.contactNo || data?.landline}</p>
            </div>
            <div className="box">
              <strong>Email : </strong>
              <p>{data?.email}</p>
            </div>
          </div>
          <div className="p-col-4">
            <div className="box">
              <strong>Accident Time : </strong>
              <p>{data?.acctime + " " + data?.accdate.split("T")[0]}</p>
            </div>
            <div className="box">
              <strong>Accident Description : </strong>
              <p>{data?.description}</p>
            </div>
            <div className="box">
              <strong>Injury Description : </strong>
              <p>{data?.injurydescription}</p>
            </div>
          </div>
          <div className="p-col-4">
            <div className="box">
              <strong>Introducer : </strong>
              <p>{data?.introducer}</p>
            </div>
            <div className="box">
              <strong>Vehicle : </strong>
              <p>{data?.registrationno}</p>
            </div>
            {/* to be confimed */}
            <div className="box">
              <strong>Hire Company : </strong>
              <p>{data?.solicitor}</p>
            </div>
            <div className="box">
              <strong>Last Updated : </strong>
              <p>{data?.lastupdated}</p>
            </div>
            <div className="box">
              <strong>Last Note : </strong>
              <p>{data?.lastnote}</p>
            </div>
            <div className="box">
              <strong>Solicitor: </strong>
              <p>{data?.solicitor}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    getHire(currentStatus);
  }, [currentStatus]);

  useEffect(() => {
    onGlobalFilterChange();
  }, [hireList]);

  const onGlobalFilterChange = (e = false) => {
    const keys = [
      "created",
      "hirenumber",
      "firstname",
      "contactdue",
      "code",
      "client",
      "status",
    ];
    const value = e?.target?.value;
    if (value) {
      const filteredList = hireList.filter((item) => {
        let flag = false;
        keys.forEach((key) => {
          if (key.includes(".")) {
            const [parent, child] = key.split(".");
            if (
              item[parent][child].toLowerCase().includes(value.toLowerCase())
            ) {
              flag = true;
            }
          } else if (
            key &&
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
            flag = true;
        });
        if (flag) return item;
      });
      setFiltered(filteredList);
    } else {
      setFiltered(hireList);
    }
  };

  const statusTemplate = (rowData) => {
    return (
      <center>
        <Chip label={rowData?.status} className="p-px-4 custom-chip" />
      </center>
    );
  };

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText onChange={onGlobalFilterChange} placepolder="Search" />
        </span>
      </div>
    );
  };

  const header2 = renderHeader2();

  return (
    <>
      <CaseHeader
        title={"Hire Case List"}
        subTitle={"All claims, status: All"}
        search
        onSearch={(val) => console.log(val)}
      >
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeader>
      <HireFilterCaseListButton />
      <TabWrapper>
        <Tab
          title={"All"}
          count={String(totalStatus())}
          active={currentStatus === "All"}
          icon={<AiOutlineBars />}
          onClick={() => setCurrentStatus("All")}
        />
        {hireStatus &&
          hireStatus.map(({ statusName, statusCount, statusCode }) => (
            <Tab
              key={statusCode}
              title={statusName}
              count={String(statusCount)}
              onClick={() => setCurrentStatus(statusCode)}
              active={currentStatus === statusCode}
              icon={<AiOutlineBars />}
            />
          ))}
      </TabWrapper>

      <div className="card">
        <DataTable
          value={filtered || []}
          showGridlines
          responsiveLayout="scroll"
          header={header2}
          expandedRows={expandedRows}
          dataKey="code"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          paginator
          rows={10}
        >
          <Column expander style={{ width: "2.5rem" }}></Column>
          <Column field="created" header="Created" sortable></Column>
          <Column field="code" header="Code" sortable></Column>
          <Column field="client" header="Client" sortable></Column>
          <Column field="contactDue" header="Task Due" sortable></Column>
          <Column field="status" body={statusTemplate} header="Status"></Column>
          <Column header="Acts" body={actionsTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const StyledIcon = styled.span`
  margin-right: 5px;
`;

export default ViewHireList;
