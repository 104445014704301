import { Dialog } from "primereact/dialog";
import React from "react";
import ChangeSolForm from "./ChangeSolForm";

function ChangeSolDialog({
  showModal,
  onCloseModal,
  campaignName,
  getCampaignDetails,
  campaignCaseCode,
  solicitorcompanycode,
}) {
 
  return (
    <Dialog
      header="Change Solicitor User"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={onCloseModal}
      draggable={false}
      resizable={false}
      position="top"
    >
      <ChangeSolForm
        campaignName={campaignName}
        getCampaignDetails={getCampaignDetails}
        campaignCaseCode={campaignCaseCode}
        onCloseModal={onCloseModal}
        solicitorcompanycode={solicitorcompanycode}
      />
    </Dialog>
  );
}

export default ChangeSolDialog;
