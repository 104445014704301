import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import SideBox, {
  SideBoxWrapper,
} from "../../components/FormLayout/SideBoxSection";
import { GiEnvelope } from "react-icons/gi";
import { AiFillPrinter, AiOutlineRedo } from "react-icons/ai";
import ReactToPrint from "react-to-print";
import { handleGetRequest } from "../../services/GetTemplate";
import { useParams } from "react-router-dom";
import Logo from "../../assets/images/TCGlogo.png";
import { handlePostRequest } from "../../services/PostTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SplitButton } from "primereact/splitbutton";
import styled from "styled-components";
import { formatAmount } from "./invoiceDetails";

function ManualInvoiceDetails() {
  let componentRef = useRef();
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [statusOpitions, setstatusOpitions] = useState("");
  const [totalValue, settotalValue] = useState("");
  const [subtotalValue, setsubtotalValue] = useState("");
  const { id } = useParams();
  const invoiceId = id;

  const handleStausChange = async (statuscode) => {
    const data = {
      hdrClaimCode: invoiceDetails?.invoiceheadid,
      toStatus: statuscode,
      reason: null,
    };
    await handlePostRequest(data, "invoicing/performActionOnInvoicing");
    getInvoiceDetails();
  };

  const getInvoiceDetails = async () => {
    const res = await handleGetRequest(
      `invoicing/getManualInvoice/${invoiceId}`
    );
    setInvoiceDetails(res?.data);
    const subTotal = res?.data?.tblManualinvoicingdetails?.reduce(
      (acc, item) => acc + item.amount,
      0 || 0
    );
    setsubtotalValue(subTotal);
    const statusOptions = res?.data?.tblRtastatuses?.map((item) => {
      return {
        label: item.descr,
        command: (e) => {
          handleStausChange(item.statuscode);
        },
      };
    });
    setstatusOpitions(statusOptions);
  };

  const amountTemplate = ({ amount }) => {
    return "£" + amount;
  };

  const sendInvoiceEmail = async () => {
    const data = {
      invoiceId: invoiceDetails?.invoiceno,
    };
    try {
      const res = await handlePostRequest(
        data,
        `invoicing/sendManualInvoiceEmail`
      );
      window.open(res?.data?.InvoiceUrl, "_blank");
    } catch (e) {
      console.log(e);
    }
    getInvoiceDetails();
  };

  const dateTimeToDate = (dateTime) => new Date(dateTime).toLocaleDateString();

  const dueDate = (date) => {
    //add two days to date
    const date1 = new Date(date);
    const dateNp = date1.setDate(date1.getDate() + 2);
    //convert milliseconds to yyyy/mm/dd
    return new Date(dateNp).toLocaleDateString();
  };

  useEffect(() => {
    settotalValue(subtotalValue + invoiceDetails?.tax);
  }, [invoiceDetails?.tax, subtotalValue]);

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  return (
    <div ref={(el) => (componentRef = el)}>
      {" "}
      <SideBoxWrapper className="w-100">
        <SideBox title={invoiceDetails?.invType === "C" ? "Credit" : "Invoice"}>
          <div id="printable" className="p-4">
            <div className="d-flex justify-content-between">
              <div className="col-md-6">
                <img src={Logo} alt="The claims group" width={60} />
              </div>
              <div className="col-md-3 offset-md-3">
                <div className="row">
                  <div className="col-md-6">
                    {invoiceDetails?.invType === "C" ? "Credit#" : "Invoice#"}
                  </div>
                  <div className="col-md-6">
                    <strong>{invoiceDetails?.manualinvoicingid}</strong>
                  </div>
                  <div className="col-md-6">
                    {invoiceDetails?.invType === "C"
                      ? "Credit Date"
                      : "Invoice Date"}
                  </div>
                  <div className="col-md-6">
                    <strong>
                      {invoiceDetails.createdate
                        ? dateTimeToDate(invoiceDetails?.createdate)
                        : "-"}
                    </strong>
                  </div>
                  <div className="col-md-6">Due Date</div>
                  <div className="col-md-6">
                    <strong>{dueDate(invoiceDetails?.duedate)}</strong>
                  </div>
                </div>
              </div>
            </div>
            <hr className="bold-line"></hr>
            <div className="row">
              <div className="col-md-4">
                <h6 className="text-semibold">
                  {invoiceDetails?.invType === "C" ? "Credit To" : "Invoice To"}
                </h6>
                <ul className="pl-1">
                  <li>{invoiceDetails?.customername}</li>
                  <li>{invoiceDetails?.customeraddress}</li>
                </ul>
              </div>
              <div className="col-md-4">
                <h6 className="text-semibold">
                  {invoiceDetails?.invType === "C"
                    ? "Credit From"
                    : "Invoice From"}
                </h6>
                <ul className="pl-1">
                  <li>{invoiceDetails?.fromCompanyName}</li>
                  <li>{invoiceDetails?.fromCompanyAddress}</li>
                </ul>
              </div>
              <div className="col-md-4">
                <h6 className="text-semibold">
                  {invoiceDetails?.invType === "C"
                    ? "Credit Details:"
                    : "Invoice Details:"}
                </h6>
                <ul className="pl-1">
                  {invoiceDetails?.invType === "I" && (
                    <>
                      <li>
                        <div className="d-flex justify-content-between">
                          <span>Account Name</span>
                          <span>{invoiceDetails?.accountName}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between">
                          {" "}
                          <span>Account Number </span>
                          <span>{invoiceDetails?.accountNumber}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between">
                          {" "}
                          <span>Sort Code </span>
                          <span>{invoiceDetails?.sortcode}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between">
                          {" "}
                          <span>Vat number </span>
                          <span>{invoiceDetails?.varegno}</span>
                        </div>
                      </li>
                    </>
                  )}
                  <li>
                    <div className="d-flex justify-content-between">
                      <span>Total Instructions</span>
                      <span>{invoiceDetails?.totalInstructions}</span>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex justify-content-between">
                      {" "}
                      <span>Issued By</span>
                      <span>{invoiceDetails?.issuedBy}</span>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex justify-content-between">
                      {" "}
                      <span>Payment Method</span>
                      <span>{invoiceDetails?.paymentMethod}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <StyledInvoiceDataTable
              className="p-0"
              value={invoiceDetails?.tblManualinvoicingdetails}
              showGridlines
              responsiveLayout="scroll"
            >
              <Column
                field="description"
                sortable
                header="Description"
                width="80%"
              ></Column>
              <Column body={amountTemplate} sortable header="Amount"></Column>
            </StyledInvoiceDataTable>
            <div className="row mt-4">
              <div className="col-md-4 offset-md-8">
                <h6 className="text-semibold">
                  Total: {formatAmount(totalValue)}
                </h6>
                <hr className="my-2" />
                <div className="d-flex justify-content-between">
                  <span className="text-semibold">Subtotal:</span>
                  <span>{formatAmount(subtotalValue)}</span>
                </div>
                <hr className="my-2" />
                <div className="d-flex justify-content-between">
                  <span className="text-semibold">VAT :</span>
                  <span>{formatAmount(invoiceDetails?.tax)}</span>
                </div>
                <hr className="my-2"></hr>
                <div className="d-flex justify-content-between">
                  <span className="text-semibold">Total :</span>
                  <span>{formatAmount(totalValue)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-2 " id="hide">
            <button
              className="btn  btn-warning hide-on-print p-ml-2 p-mr-2"
              onClick={sendInvoiceEmail}
            >
              Email
            </button>
            &nbsp; &nbsp;
            <SplitButton
              label="Savedsd"
              icon="pi pi-plus"
              model={statusOpitions}
            ></SplitButton>
          </div>
        </SideBox>
      </SideBoxWrapper>
    </div>
  );
}

export default ManualInvoiceDetails;

const StyledInvoiceDataTable = styled(DataTable)`
  td:first-child {
    width: 70%;
  }
  td:nth-child(2) {
    width: 30%;
  }
`;
