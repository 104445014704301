import React, { useEffect, useState } from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import LogEmpty from "../Logs/LogEmpty";
import CaseInvoicingList from "./CaseInvoicingList";
import { useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import styled from "styled-components";

// Styled Spinner
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
`;

const Spinner = styled(FaSpinner)`
  font-size: 2rem;
  color: #3498db;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function CaseInvoicing({ api, caseCode, children }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCategory = user?.tblUsercategory?.categorycode;

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await handleGetRequest(`${api}/${caseCode}`, false);
        const filteredData = response.data?.filter((item) => {
          if (userCategory === "1") return item.invoiceType === "I";
          if (userCategory === "2") return item.invoiceType === "S";
          if (userCategory === "4") return true;
          return false; // Else don't show anything
        });

        setData(filteredData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Stop loading after API call
      }
    };

    if (caseCode) {
      getData();
    }
  }, [api, caseCode, userCategory]);

  // Show spinner while loading
  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  // Show empty state if no data
  if (!data || data.length === 0) {
    return <LogEmpty />;
  }

  return (
    <>
      {children}
      <CaseInvoicingList invoiceList={data} campaigncasecode={caseCode} />
    </>
  );
}

export default CaseInvoicing;
