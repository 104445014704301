import React, { useEffect, useState } from "react";
import LogComponent from "./LogComponent";
import { handleGetRequest } from "../../services/GetTemplate";
import LogEmpty from "./LogEmpty";
import { FaSpinner } from "react-icons/fa";
import styled from "styled-components";

// Styled Spinner
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
`;

const Spinner = styled(FaSpinner)`
  font-size: 2rem;
  color: #3498db;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function Logs({ api, caseCode }) {
  const [logs, setLogs] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    const getLogs = async () => {
      try {
        const response = await handleGetRequest(`${api}/${caseCode}`, false);
        setLogs(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Stop loading after API call
      }
    };

    if (caseCode) {
      getLogs();
    }
  }, [api, caseCode]);

  // Show spinner while loading
  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  // Show empty state if no logs
  if (!logs || logs.length === 0) {
    return <LogEmpty />;
  }

  return <LogComponent logs={logs} />;
}

export default Logs;
