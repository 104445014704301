import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../services/GetTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { campaignConfig } from "../CampaignGeneric/campaignConfig";
import { handlePostRequest } from "../../services/PostTemplate";

function ChangeSolForm({
  campaignName,
  getCampaignDetails,
  campaignCaseCode,
  onCloseModal,
  solicitorcompanycode,
}) {
  const [solicitorOptions, setsolicitorOptions] = useState("");
  const { changeSolApi, lovSolUserApi } = campaignConfig[campaignName];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const funcgetSolicitorsForRta = async () => {
    const res = await handleGetRequest(lovSolUserApi + solicitorcompanycode);
    setsolicitorOptions(res.data);
  };

  useEffect(() => {
    funcgetSolicitorsForRta();
  }, []);

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      solicitor: Yup.mixed().required("required"),
    }),
    initialValues: {
      solicitor: "",
    },
    onSubmit: async (data) => {
      const payload = {
        solicitor: data?.solicitor?.code.toString(),
        solicitorcompanycode,
        code: campaignCaseCode,
      };
      try {
        setLoading(true);
        await handlePostRequest(payload, changeSolApi);
        setLoading(false);
        onCloseModal();
        dispatch(getCampaignDetails(campaignCaseCode));
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid  mt-4">
          <div className="p-col-12 p-md-12">
            <label className="p-d-block">Solicitor User *</label>
            <Dropdown
              filter
              id="solicitor"
              name="solicitor"
              options={solicitorOptions}
              value={formik.values.solicitor}
              optionLabel="name"
              placeholder={loading ? "Loading..." : "Select Solicitor User"}
              onChange={(e) => formik.setFieldValue("solicitor", e.value)}
              className={classNames({
                "p-invalid": isFormFieldValid("solicitor"),
                Width__100: true,
              })}
            />
            {getFormErrorMessage("solicitor")}
          </div>
        </div>
      </form>
      <div align="center" className="mt-4">
        <Button
          type="submit"
          icon={loading ? "pi pi-spin pi-spinner" : ""}
          className="btn btn-primary"
          label="Update"
          onClick={formik.handleSubmit}
          disabled={loading}
        ></Button>
      </div>
    </div>
  );
}

export default ChangeSolForm;
