import React, { useState } from "react";
import { Button } from "primereact/button";
import { CSVLink } from "react-csv";
import { FaFileExcel } from "react-icons/fa";
import { handlePostRequest } from "../../services/PostTemplate";

function ExportAllCasesHdr() {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);

  const headers = [
    { label: "Date Created", key: "createdate" },
    { label: "Date Updated", key: "updatedate" },
    { label: "Claim Code", key: "claimcode" },
    { label: "Name", key: "name" },
    { label: "Introducer Company", key: "introducerCompany" },
    { label: "Introducer User", key: "introducerUser" },
    { label: "Solicitor Company", key: "solicitorCompany" },
    { label: "Solicitor User", key: "solicitorUser" },
    { label: "Case Status", key: "caseStatus" },
    { label: "Case Status On", key: "caseStatusOn" },
    { label: "Invoice Date", key: "invoiceDate" },
    { label: "CFA", key: "cfa" },
    { label: "Proof of ID", key: "proofOfId" },
    { label: "Proof of Address", key: "proofOfAddress" },
    { label: "AML", key: "aml" },
    { label: "Tenancy Agreement", key: "tenancyAgreement" },
    { label: "Landlord", key: "landlord" },
    { label: "Post Code", key: "cpostcode" },
  ];

  const exportAllCases = async () => {
    setLoading(true);
    try {
      const data = {
        fromDate: "01-01-2023",
        toDate: new Date()
          .toISOString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("-"),
      };
      const res = await handlePostRequest(data, "hdr/hdrCaseReport");
      setCases(res.data);
    } catch (error) {
      console.error("Error fetching all cases:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <Button disabled={loading} onClick={exportAllCases}>
        {loading ? "Fetching..." : "Export All"}
      </Button>
      {cases.length > 0 && (
        <CSVLink
          data={cases}
          headers={headers}
          filename={`hdr_report_all_${new Date()
            .toISOString()
            .slice(0, 10)}.csv`}
        >
          <Button>
            <FaFileExcel />
            &nbsp;Download
          </Button>
        </CSVLink>
      )}
    </>
  );
}

export default ExportAllCasesHdr;
