import React, { useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  verifyCaptchaFailureAction,
  verifyCaptchaSuccessAction,
} from "../../redux/actions/verifyCaptuaAction";

export const SITE_KEY = "f20855d8-bb4e-4ed8-bdd6-c7d0ac9eb6b6";

// Styled components
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 2rem;
  max-width: 400px;
  margin: 2rem auto;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
  }
`;

const StyledReCAPTCHA = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Captcha = ({ onCloseModal }) => {
  const [captchaToken, setCaptchaToken] = useState("");
  const dispatch = useDispatch();

  const handleVerify = (token) => {
    setCaptchaToken(token); // Save the token from reCAPTCHA
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      toast.error("Please verify the CAPTCHA!");
      dispatch(verifyCaptchaFailureAction());
      return;
    }

    dispatch(verifyCaptchaSuccessAction());

    onCloseModal();
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <StyledReCAPTCHA>
        <HCaptcha sitekey={SITE_KEY} onVerify={handleVerify} size="normal" />
      </StyledReCAPTCHA>
      <StyledButton
        type="button"
        disabled={!captchaToken}
        onClick={handleSubmit}
      >
        Verify CAPTCHA
      </StyledButton>
    </FormContainer>
  );
};

export default Captcha;
