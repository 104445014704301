import React, { useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import styled from "styled-components";
import { FaFileInvoice, FaUserTie } from "react-icons/fa";
import LaCheckbox from "../../components/LaCheckbox";
import { handlePostRequest } from "../../services/PostTemplate";
import { useSelector } from "react-redux";

// Styled Components
const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 2rem;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: #ffffff;
`;

const Title = styled.h3`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 2rem;
  font-weight: 600;
`;

const FormGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: #f8f9fa;
  transition: all 0.3s ease;

  &:hover {
    background: #f1f3f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #34495e;
  font-size: 1.1rem;
`;

const SubmitButton = styled(Button)`
  margin-top: 2rem;
  background: #3498db;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 2rem;
  transition: all 0.3s ease;

  &:hover {
    background: #2980b9;
    transform: translateY(-2px);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function UpdateInvoicingStatus({
  formData,
  campaignCode,
  caseCode,
  onUpdated,
}) {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);

  const user = userStore && JSON.parse(userStore);

  const initialValues = {
    isSolicitorInvoice: "",
    isIntroducerInvoice: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (data) => {
      const updatedData = {
        isSolicitorInvoice: data.isSolicitorInvoice ? "Y" : "N",
        isIntroducerInvoice: data.isIntroducerInvoice ? "Y" : "N",
        campaignCode,
        caseCode,
      };

      try {
        await handlePostRequest(
          updatedData,
          "updateSolicitorIntroducerInvoiceFlag"
        );
        formik.resetForm();
        onUpdated();
      } catch (e) {
        console.log(e);
      }
    },
  });

  useEffect(() => {
    if (formData) {
      formik.setFieldValue(
        "isSolicitorInvoice",
        formData?.isSolicitorInvoice === "Y"
      );
      formik.setFieldValue(
        "isIntroducerInvoice",
        formData?.isIntroducerInvoice === "Y"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  if (user?.tblUsercategory?.categorycode !== "4") {
    return null;
  }

  return (
    <Container>
      <Title>Update Invoicing Status</Title>
      <form onSubmit={formik.handleSubmit}>
        <FormGrid>
          <CheckboxContainer>
            <LaCheckbox keyName="isIntroducerInvoice" {...props} />
            <StyledLabel>
              <FaFileInvoice color="#3498db" />
              Is Introducer Invoiced?
            </StyledLabel>
          </CheckboxContainer>

          <CheckboxContainer>
            <LaCheckbox keyName="isSolicitorInvoice" {...props} />
            <StyledLabel>
              <FaUserTie color="#3498db" />
              Is Solicitor Invoiced?
            </StyledLabel>
          </CheckboxContainer>

          <ButtonContainer>
            <SubmitButton type="submit" label="Update" />
          </ButtonContainer>
        </FormGrid>
      </form>
    </Container>
  );
}

export default UpdateInvoicingStatus;
