import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  verifyCaptchaFailureAction,
  verifyCaptchaSuccessAction,
} from "../../redux/actions/verifyCaptuaAction";

// Constants for positions and dimensions
const SLIDER_MAX = 280; // Maximum slider value for the X-axis
const VERTICAL_MAX = 200; // Maximum value for the Y-axis
const DRAGGABLE_SIZE = 50; // Size of the draggable item
const GRACE_MARGIN = 5; // Grace margin in pixels

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const PuzzleContainer = styled.div`
  position: relative;
  width: 300px;
  height: 200px; // Increased height for Y-axis
  border: 2px dashed #ccc;
  margin: 20px;
  display: flex;
  align-items: flex-start; // Align items at the top
  justify-content: flex-start;
  background: #f9f9f9;
`;

const TargetBox = styled.div`
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  width: ${DRAGGABLE_SIZE}px;
  height: ${DRAGGABLE_SIZE}px;
  background: rgba(255, 0, 0, 0.3);
  border: 2px dashed red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: red;
`;

const Slider = styled.input`
  width: 300px;
  margin-top: 20px;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

const DraggableItem = styled.div`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${DRAGGABLE_SIZE}px;
  height: ${DRAGGABLE_SIZE}px;
  background: ${(props) => (props.success ? "#4caf50" : "#ccc")};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: top 0.2s ease-in-out, left 0.2s ease-in-out,
    background 0.2s ease-in-out;
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => (props.success ? "green" : "red")};
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 30px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
  }
`;

const StyledHeading = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const DragCaptcha = ({ onCloseModal }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [success, setSuccess] = useState(false);
  const [targetPosition, setTargetPosition] = useState({ x: 0, y: 0 });
  const dispatch = useDispatch();

  // Generate random position for the target box when the component mounts
  useEffect(() => {
    const randomTargetX = Math.floor(
      Math.random() * (SLIDER_MAX - DRAGGABLE_SIZE)
    );
    const randomTargetY = Math.floor(
      Math.random() * (VERTICAL_MAX - DRAGGABLE_SIZE)
    );
    setTargetPosition({ x: randomTargetX, y: randomTargetY });
  }, []);

  const handleSliderChange = (event) => {
    const newPosition = parseInt(event.target.value, 10);
    setPosition({ ...position, x: newPosition });

    // Ensure the entire draggable item fits within the target box with grace margin
    const isSuccess =
      newPosition >= targetPosition.x - GRACE_MARGIN &&
      newPosition + DRAGGABLE_SIZE <=
        targetPosition.x + DRAGGABLE_SIZE + GRACE_MARGIN &&
      position.y >= targetPosition.y - GRACE_MARGIN &&
      position.y + DRAGGABLE_SIZE <=
        targetPosition.y + DRAGGABLE_SIZE + GRACE_MARGIN;

    setSuccess(isSuccess);
  };

  const handleVerticalChange = (event) => {
    const newPositionY = parseInt(event.target.value, 10);
    setPosition({ ...position, y: newPositionY });

    // Check success condition for Y-axis as well
    const isSuccess =
      position.x >= targetPosition.x - GRACE_MARGIN &&
      position.x + DRAGGABLE_SIZE <=
        targetPosition.x + DRAGGABLE_SIZE + GRACE_MARGIN &&
      newPositionY >= targetPosition.y - GRACE_MARGIN &&
      newPositionY + DRAGGABLE_SIZE <=
        targetPosition.y + DRAGGABLE_SIZE + GRACE_MARGIN;

    setSuccess(isSuccess);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!success) {
      toast.error("Please verify the CAPTCHA!");
      dispatch(verifyCaptchaFailureAction());
      return;
    }

    dispatch(verifyCaptchaSuccessAction());

    onCloseModal();
  };

  return (
    <Container>
      <StyledHeading>
        Complete the CAPTCHA: Slide the object into the box
      </StyledHeading>
      <PuzzleContainer>
        <TargetBox left={targetPosition.x} top={targetPosition.y}>
          Fit Here
        </TargetBox>
        <DraggableItem
          left={position.x}
          top={position.y}
          success={success}
          aria-label="Draggable item"
        >
          ✔
        </DraggableItem>
      </PuzzleContainer>
      <Slider
        type="range"
        min="0"
        max={SLIDER_MAX}
        value={position.x}
        onChange={handleSliderChange}
        aria-label="Slider to move the draggable item horizontally"
      />
      <Slider
        type="range"
        min="0"
        max={VERTICAL_MAX}
        value={position.y}
        onChange={handleVerticalChange}
        aria-label="Slider to move the draggable item vertically"
      />
      {success && (
        <Message success aria-live="polite">
          ✔ Verified
        </Message>
      )}
      <StyledButton type="button" disabled={!success} onClick={handleSubmit}>
        Verify CAPTCHA
      </StyledButton>
    </Container>
  );
};

export default DragCaptcha;
